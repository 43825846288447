import React, { useState, useEffect, useContext } from "react";
import Header from "../../Components/Header/Header";
import { Table, Container } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import Formatter from "../../Helper/Formatter";
import "./Purchases.css";
import ReactPaginate from 'react-paginate';

function Purchases() {
  const { purchaseData } = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const ProgramItems = purchaseData.slice(indexOfFirstItem, indexOfLastItem);
  const handlePageChange = ({ selected }) => {
  setCurrentPage(selected);
  };

  return (
    <>
      <Header />
      <main>
    <div className="container-fluid purchase-cont">
    <section class="main">
          <h2 className="text-center">Purchases</h2>
          <div className="card inst_card mt-3 table-responsive" id="cat_card">
            <Table className="show-table">
              <table role="table" className="table table-bordered show-table">
                <thead style={{ textAlign: "center" }}>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Therapy</th>
                    <th>Therapist</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {ProgramItems &&
                    ProgramItems.length > 0 &&
                    ProgramItems.map((purchase, index) => {
                      const therapist = purchase?.therapist;
                      const date = new Date(purchase.date);
                      const hours = date.getHours().toString().padStart(2, "0");
                      const minutes = date
                        .getMinutes()
                        .toString()
                        .padStart(2, "0");
                      const time = `${hours}:${minutes}`;

                      const therapyDetails = purchase?.therapy;

                      return (
                        <tr>
                          <td style={{ textAlign: "center" }} key={index}>{index + 1}</td>
                          <td>{purchase?.userDetails?.name ?? "N/A"}</td>
                          <td>{purchase?.userDetails?.email ?? "N/A"}</td>
                          <td style={{ textAlign: "center" }}>{Formatter.formatDate(purchase?.date)}</td>
                          <td style={{ textAlign: "center" }}>
                            {time != null ? Formatter.formatTime(time) : "N/A"}
                          </td>
                          <td>{therapyDetails?.therapyName ?? "N/A"}</td>
                          <td>{therapist?.name ?? "N/A"}</td>
                          <td style={{ textAlign: "center" }}>£{purchase.amount}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Table>
          </div>  
          <div className="pagnte">
            <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel="..."
        pageCount={Math.ceil(purchaseData.length / itemsPerPage)}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        previousLinkClassName={"page-link"}
        nextLinkClassName={"page-link"}
        disabledClassName={"disabled"}
        activeClassName={"paging__link--active"}
      />
            </div>
        </section>
    </div>
      </main>
    </>
  );
}

export default Purchases;
