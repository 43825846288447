import BaseClient from "../Helper/BaseClient";
import { useState } from "react";
import { APIEndpoints } from "../constants/APIEndpoints";

const useTherapist = () => {
  const [actionLoading, setActionLoading] = useState({
    action: "",
    loading: false,
  });
  /* update details */
  const editInstructor = async (data, id, { onSuccess, onFailed }) => {
    try {
      setActionLoading({ action: "Edit", loading: true });

      await BaseClient.put(APIEndpoints.editInstructor + `/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setActionLoading({ action: "", loading: false });
    }
  };


  
  return {
    editInstructor,
    actionLoading,
  }; 
};
export default useTherapist;
