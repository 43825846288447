import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import Utils from "../../Utils/Utils";

function StreamsList({
  onStreamStart,
  showStart,
  finished,
  streams,
  therapist,
}) {
  const { programs, registerdUsers } = useContext(AppContext);

  return (
    <>
      <Container>
        {streams &&
          streams.length > 0 &&
          streams.map((stream) => {
            console.log("status_", stream.scheduleStatus);
            const schedule = stream?.scheduleDetails;
            const userId = stream.userIds;
            const therapy = programs.find((x) => x._id == schedule.therapyId);
            const findUser = registerdUsers.filter((y) =>
              userId.includes(y._id)
            );

            return (
              <div class="card schedule_card schedule_details_card mt-3">
                <Row>
                  <Col xl={3}>
                    <p style={{ fontWeight: "500" }}>
                      Therapist Name : <span>{therapist?.name ?? "N/A"}</span>
                    </p>
                    <p style={{ fontWeight: "500" }}>
                      Schedule Period: <span>Weekly</span>
                    </p>
                    <div className="det">
                      <div className="icon_div">
                        <p style={{ fontWeight: "500" }}>
                          Client Name :{" "}
                          <span>
                            {findUser.map((name) => name.name).join(", ")}
                          </span>
                        </p>
                      </div>
                      <div className="icon_div">
                        <p style={{ fontWeight: "500" }}>
                          Time :{" "}
                          <span>
                            {schedule?.startTime ?? "N/A"} to{" "}
                            {schedule?.endTime ?? "N/A"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xl={6}>
                    <p style={{ fontWeight: "500" }}>
                      Therapy Name :{" "}
                      <span>{therapy?.therapyName ?? "N/A"}</span>{" "}
                    </p>
                    <p style={{ fontWeight: "500" }}>
                      Date :{" "}
                      <span>
                        {stream.date != null
                          ? Utils.formatDate(stream.date)
                          : "N/A"}
                      </span>{" "}
                    </p>
                    <p className="des_">
                      {therapy?.therapyDescription ?? "N/A"}
                    </p>
                    {/* <div className="icon_div">
                    <label for="">Time</label>
                    <space /> <input type="time" /> to <input type="time" />
                    </div>
                     */}
                  </Col>
                  <Col xl={3}>
                    <div className="row mt-3">
                      {stream.scheduleStatus == "pending" ? (
                        <div class="start_stream_btn mx-auto not_acc_">
                          Not Accepted
                        </div>
                      ) : (
                        <button
                          class="start_stream_btn mx-auto"
                          onClick={() =>
                            onStreamStart({ ...stream, therapist })
                          }
                          // disabled={!selectedTherpy}
                        >
                          {!finished ? "Start" : "Restart"}
                        </button>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })}
      </Container>
    </>
  );
}

export default StreamsList;
