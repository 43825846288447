import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import "./Instructors.css";
import { Row, Col, Container } from "react-bootstrap";
import { Button } from "antd";
import Profile from "../../assets/placeholder.png";
import Avatar from "react-avatar-edit";
import { Dialog } from "primereact/dialog";
import "antd/dist/reset.css";
import useInstructor from "../../hook/useInstructor";
import { AppContext } from "../../context/AppContext";
import * as Ai from "react-icons/ai";
import toast, { Toaster } from "react-hot-toast";
import { BeatLoader } from "react-spinners";
import { confirmAlert } from "react-confirm-alert"; // Import
import Utils from "../../Utils/Utils";
function AddInstructors() {
  const navigate = useNavigate();
  const { getTherapist, actionLoading, addTheraphist } = useContext(AppContext);
  const [therapy, setTherapy] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [imageValid, setImageValid] = useState(true);
  const [phonenumber, setPhonenumber] = useState("");
  const [file, setFile] = useState(null);
  const [experience, setExperience] = useState("");
  const [desigination, setDesignation] = useState("");
  const [bio, setBio] = useState("");
  const [email, setEmail] = useState("");
  const [profession, setProfession] = useState("");
  const [error, setError] = useState(false);
  const [dialogues, setDialogues] = useState(false);
  const [showOther, setShowOther] = useState(false);
  const [duration, setDuration] = useState("year");
  const [preview, setPreview] = useState(null);
  const [price, setPrice] = useState("");

  const onClose = (view) => {};

  const onCrop = (value) => {
    setPreview(value);
    setFile(Utils.createBlobFile(value));
  };

  const saveDoc = (e) => {
    setDialogues(false);
  };

  const onOptionChangeHandler = (e) => {
    setDuration(e.target.value);
  };
  const handleImageError = () => {
    setImageValid(false);
  };
  const formSubmit = async (e) => {
    e.preventDefault();

    if (firstname.length === 0 || lastname.length === 0 || email.length === 0) {
      setError(true);
      return;
    }

    let prefix = "";
    if (duration == "year") {
      prefix = duration == "year" && experience == "1" ? "year" : "years";
    } else {
      prefix = duration == "month" && experience == "1" ? "month" : "months";
    }

    const formData = new FormData();
    formData.append("firstName", firstname);
    formData.append("lastName", lastname);
    formData.append("bio", bio);
    formData.append("phonenumber", phonenumber);
    formData.append("profession", profession);
    formData.append("email", email);
    formData.append("desigination", desigination);
    formData.append("experience", experience + " " + prefix);

    formData.append("price", price);

    if (file != null) {
      formData.append("file", file, file?.name ?? "N/A");
    }

    //* Consoling formData
    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }

    await addTheraphist(formData, {
      onSuccess: async (res) => {
        getTherapist();
        if (res.error) {
          toast.error(res.message);
        }
        toast.success(res.message);
        await getTherapist();
        setTimeout(() => {
          setTherapy("");
          setFirstname("");
          setLastname("");
          setPhonenumber("");
          setFile("");
          setExperience("");
          setDesignation("");
          setBio("");
          setEmail("");
          setProfession("");

          navigate("/therapist");
        }, 1000);
      },
      onFailed: (e) => {
        toast.error(e.message + " , Please check all fields are filled");
      },
    });
  };
  const showOtherInput = () => {
    if (profession === "other") {
      setShowOther(true);
    } else {
      setShowOther(false);
      document.getElementById("other_inp").value = "";
    }
  };
  const changeToNumber = (str) => {
    var reg = /^\d+$/;

    if (str === "" || reg.test(str)) {
      setPhonenumber(str);
    }
  };
  const handleLogout = () => {
    setIsLoading(false);

    setTimeout(() => {
      navigate("/therapist");
    }, 1000);
  };
  const submitPrev = () => {
    confirmAlert({
      message: "Are you sure, want to go back?",
      buttons: [
        {
          className: "confirm-btn",
          label: "Yes",
          onClick: () => handleLogout(),
        },
        {
          className: "clear-button",
          label: "No",
        },
      ],
    });
  };

  return (
    <>
      <Toaster position="top-center" />
      <header>
        <Header />
      </header>
      <Container className="add_cont">
        <main>
          <section className="main ml-3">
            <button className="back_btn" onClick={submitPrev}>
              <Ai.AiOutlineLeft />
            </button>
            <h2>Add Therapist</h2>
            <form
              className="form-control add_form"
              onSubmit={(e) => formSubmit(e)}
            >
              <Row>
                <Col required>
                  <div className="profile">
                    <div
                      className="flex flex-column justify-content-center align-items-center"
                      required
                    >
                      {imageValid ? (
                        <img
                          src={preview != null ? preview : Profile}
                          alt=""
                          value="image"
                          style={{ maxWidth: "100%" }}
                          onClick={() => {
                            setDialogues(true);
                          }}
                          onError={handleImageError}
                        />
                      ) : (
                        <div>image not uploaded</div>
                      )}

                      <Dialog
                        visible={dialogues}
                        header={() => (
                          <>
                            <p>Update Profile Picture</p>
                          </>
                        )}
                        onHide={() => setDialogues(false)}
                        required
                      >
                        <div className="confirmation-content flex flex-column align-items-center">
                          <div className="flex flex-column align-items-center mt-5 w-12">
                            <div className="flex flex-column justify-content-around w-12 mt-4">
                              <Avatar
                                width={400}
                                height={300}
                                onClose={(e) => onClose(e)}
                                onCrop={(e) => onCrop(e)}
                              />
                              <Button
                                label="Save"
                                onClick={saveDoc}
                                className="mt-4"
                              >
                                Save
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Dialog>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl={6}>
                  <div className="form-group">
                    <label for="firstname">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="firstname"
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                    />
                    {error && firstname?.length === 0 ? (
                      <>
                        <span style={{ color: "red" }}>
                          Please fill your firstname
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
                <Col xl={6}>
                  <div className="form-group">
                    <label for="lastname">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                    />
                    {error && lastname?.length === 0 ? (
                      <>
                        <span style={{ color: "red" }}>Lastname required</span>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xl={6}>
                  <div className="form-group">
                    <label for="email">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {error && email?.length === 0 ? (
                      <>
                        <span style={{ color: "red" }}>Email Required</span>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
                <Col xl={6}>
                  <div className="form-group">
                    <label for="phonenumber">Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      maxLength={15}
                      value={phonenumber}
                      onChange={(e) => changeToNumber(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mb-3 mt-3">
                <Col xl={4}>
                  <label for="exp">Experience</label>
                  <div className="joint">
                    <input
                      type="number"
                      name="experience"
                      id=""
                      class="text_fld me-2"
                      value={experience}
                      onChange={(e) => {
                        setExperience(e.target.value);
                      }}
                    />
                    <span>
                      <select
                        className="form-select"
                        style={{ width: "100%" }}
                        onChange={(e) => onOptionChangeHandler(e)}
                      >
                        <option selected value="years">
                          Years
                        </option>
                        <option value="months">Months</option>
                      </select>
                    </span>
                  </div>
                </Col>
                <Col xl={4}>
                  <div>
                    <label for="exp">Experience in</label>
                    <select
                      className="form-select"
                      onChange={(e) => setProfession(e.target.value)}
                      value={profession}
                    >
                      <option selected hidden>
                        Select
                      </option>
                      <option value="Personal Trainer">Personal Trainer</option>
                      <option value="Mental therapist">Mental therapist</option>
                      <option value="Yoga Teacher">Yoga Teacher</option>
                      <option
                        value="other"
                        onSelect={() => setShowOther(false)}
                      >
                        Other
                      </option>
                    </select>
                  </div>
                </Col>
                <Col xl={4}>
                  <label>Designation</label>
                  <input
                    type="text"
                    className="form-control"
                    value={desigination}
                    onChange={(e) => setDesignation(e.target.value)}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xl={2}>
                  <label for="Session Price">Session Price</label>
                  <input
                    name="price"
                    type="text"
                    id=""
                    class="form-control"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={12}>
                  <label for="Bio">Bio</label>
                  <textarea
                    className="bio"
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                  ></textarea>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xl={12}>
                  <button type="submit" className="form_btn">
                    {actionLoading &&
                    actionLoading.action == "Add" &&
                    actionLoading.loading ? (
                      <BeatLoader color="#fff" />
                    ) : (
                      "Add"
                    )}
                  </button>
                </Col>
              </Row>
            </form>
          </section>
        </main>
      </Container>
    </>
  );
}

export default AddInstructors;
