import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import * as Ai from "react-icons/ai";
import "react-datepicker/dist/react-datepicker.css";
import "./Home.css";
import { AppContext } from "../../context/AppContext";
import { Row } from "react-bootstrap";
import useCookies from "../../hook/useCookies";
import TherapistDashboard from "./TherapistDashboard";

function Home() {
  const navigate = useNavigate();
  const [isRole, setIsRole] = useState(null);
  const { therapists, registerdUsers, scheduledSlot } = useContext(AppContext);
  const { loggedInstructorDetails } = useContext(AppContext);
  const { getValueFromCookies } = useCookies({ key: "UserPersistent" });
  useEffect(() => {
    const { role } = getValueFromCookies();
    setIsRole(role);
  }, []);

  const navigateToProfile = (id) => {
    navigate(`/profile-instructor/${id}`);
  };

  return (
    <>
      <header>
        <Header />
      </header>
      {/* header end */}
      <main>
        {isRole === "superadmin" && (
          <section class="main ml-3">
            <h1>Welcome Admin</h1>
            <div class="container ml-3">
              <Row>
                <div className="col-lg-3 col-md-3 col-sm-6 ">
                  <div className="card dash_card instructor">
                    <h3>Therapists</h3>
                    <h2>{therapists.length}</h2>
                    <div className="dash_icon ">
                      <img src={require("../../assets/teacher.png")} alt="" />
                    </div>
                    <button
                      onClick={() => navigate("/therapist")}
                      className="dash_link"
                    >
                      <Ai.AiOutlineArrowRight />
                    </button>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <div className="card dash_card instructor">
                    <h3>Clients</h3>
                    <h2>{registerdUsers.length}</h2>
                    <div className="dash_icon ">
                      <img src={require("../../assets/team.png")} alt="" />
                    </div>
                    <a href="/" className="dash_link">
                      <Ai.AiOutlineArrowRight />
                    </a>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <div className="card dash_card instructor">
                    <h3>Sessions</h3>
                    <h2>{scheduledSlot.length}*</h2>
                    <div className="dash_icon ">
                      <img src={require("../../assets/webinar.png")} alt="" />
                    </div>
                    <a href="/lives" className="dash_link">
                      <Ai.AiOutlineArrowRight />
                    </a>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <div className="card dash_card instructor">
                    <h3>Upcoming</h3>
                    <h2>15/02</h2>

                    <div className="dash_icon ">
                      <img src={require("../../assets/live.png")} alt="" />
                    </div>
                    <a href="/lives" className="dash_link">
                      <Ai.AiOutlineArrowRight />
                    </a>
                  </div>
                </div>
              </Row>
              <br />
              <Row>
                <div className="col-lg-3 col-md-3 col-sm-6 mb-4">
                  <div className="card dash_card instructor">
                    <h3>Current</h3>
                    <h2 title="self management">Yoga..</h2>
                    <div className="dash_icon ">
                      <img src={require("../../assets/live-news.png")} alt="" />
                    </div>
                    <a href="/lives" className="dash_link">
                      <Ai.AiOutlineArrowRight />
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 mt-2">
                  <div class="card dash_card_table">
                    <h3>Prev sessions</h3>
                    <table class="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Title</th>
                          <th>Instructor</th>
                          <th>Users</th>
                          <th>Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Yoga</td>
                          <td>John Doritto</td>
                          <td>121</td>
                          <td>15/12/22</td>
                          <td>Success</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Personal deve..</td>
                          <td>Angela Moriatty</td>
                          <td>56</td>
                          <td>04/12/22</td>
                          <td>Success</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Trauma and emo..</td>
                          <td>Dmitry Anto</td>
                          <td>184</td>
                          <td>22/11/22</td>
                          <td>Success</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* <div className="col-lg-3 col-md-3 col-sm-3">
                <div class="card dash_card" style={{ height: "250px"}}>
                  <h3>Social Media</h3>
                  <Row>
                    <Col className="col-lg-3 col-md-3 col-sm-3 mt-2">
                      <div className="social">
                        <a href="https://www.facebook.com/" target="_blank">
                          <img src={require("../../assets/facebook.png")} />
                        </a>
                      </div>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-3 mt-2">
                      <div class="social">
                        <a href="https://www.youtube.com/" target="_blank">
                          <img src={require("../../assets/youtube.png")} />
                        </a>
                      </div>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-3 mt-2">
                      <div class="social">
                        <a href="https://www.instagram.com/" target="_blank">
                          <img src={require("../../assets/instagram.png")} />
                        </a>
                      </div>
                    </Col>
                    <Col className="col-lg-4 col-md-4 col-sm-4 mt-2">
                      <div class="social">
                        <a href="https://twitter.com/" target="_blank">
                          <img src={require("../../assets/twitter.png")} />
                        </a>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div> */}
              </Row>
            </div>
          </section>
        )}
        {isRole === "instructor" && (
          <TherapistDashboard
            loggedInstructorDetails={loggedInstructorDetails}
            navigateToProfile={navigateToProfile}
          />
        )}
      </main>
      {/* main end */}
      <footer></footer>
    </>
  );
}

export default Home;
