import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Ai from "react-icons/ai";
import { Row, Col } from "react-bootstrap";
import "./Therapy.css";
import { AppContext } from "../../context/AppContext";
import toast, { Toaster } from "react-hot-toast";
import Category from "../Category/Category";
import { BeatLoader } from "react-spinners";
import Header from "../../Components/Header/Header";

function EditTherapy() {
  const params = useParams();
  const navigate = useNavigate();
  const {
    deleteTherapy,
    isLoading,
    editTherapy,
    getTherapy,
    programs,
    categories,
  } = useContext(AppContext);
  const [therapy, setTherapy] = useState(null);
  const [therapyDesc, setTherapyDesc] = useState(null);
  const [programName, setProgramName] = useState(null);
  const [therapyCat, setTherapyCat] = useState(null);

  useEffect(() => {
    findTherapistList();
  }, []);

  useEffect(() => {
    initFunction();
  }, [therapy]);

  const findTherapistList = () => {
    const id = params.id;
    if (!id) return;
    const locTherapy = programs.find((x) => x._id === id);
    setTherapy(locTherapy);
  };

  const initFunction = () => {
    if (!therapy) return;

    const category =
      therapy?.category != null &&
      therapy?.category != undefined &&
      therapy?.category != "undefined" &&
      therapy?.category;

    if (category) {
      setTherapyCat((category)._id);
    }
    setProgramName(therapy.therapyName);
    setTherapyDesc(therapy.therapyDescription);
  };

  const editTherapySingle = (e) => {
    e.preventDefault();
    const locCategory = categories.find((program) => program._id == therapyCat);
    console.log("loc",locCategory)
    if (!locCategory) return;

    const payload = {
      therapyName: programName,
      categoryId: locCategory._id,
      therapyDescription: therapyDesc,

    };

    editTherapy(payload, params.id, {
      onSuccess: async (res) => {
        toast.success("Therapy updated sucessfully");
        await getTherapy();
        setTimeout(() => {
          navigate("/therapy");
        }, 1000);
      },
      onFailed: (err) => console.log(err),
    });
  };

  return (
    <div>
      <Toaster position="top-center" />
      <Header />
      <main>
        <section class="main">
          <div className="container">
            <div>
              <button
                className="back_btn"
                style={{ position: "absolute" }}
                onClick={() => navigate(-1)}
              >
                <Ai.AiOutlineLeft />
              </button>

              <h2 className="text-center">Edit Therapy</h2>
              <div className="edit-form">
                {therapy != null && (
                  <form
                    className="form-control"
                    onSubmit={(e) => editTherapySingle(e)}
                  >
                    <div className="form-group">
                      <label for="program name">Programme name</label>
                      <input
                        type="text"
                        name="therapyName"
                        className="form-control"
                        value={programName}
                        onChange={(e) => setProgramName(e.target.value)}
                      />
                    </div>
                    <div className="form-group mt-4">
                      <label for="exp">Category</label>
                      <select
                        name=""
                        id=""
                        className="form-select"
                        onChange={(e) => setTherapyCat(e.target.value)}
                      >
                        {categories != null &&
                          categories.map((category) => {
                            return (
                              <>
                                <option
                                  value={category._id}
                                  selected={category._id == therapyCat}
                                >
                                  {category.category}
                                </option>
                              </>
                            );
                          })}
                      </select>
                    </div>
                    <br />
                    <label for="Bio">Description</label>
                    <textarea
                      className="bio text-wrap"
                      name="therapyDescription"
                      value={therapyDesc}
                      onChange={(e) => setTherapyDesc(e.target.value)}
                    />

                    <br />
                    <Row className="buttons">
                      <Col className="text-center">
                        <Col className="mx-auto">
                          <button type="submit" className="edit_btn">
                            {isLoading.action === "Edit" &&
                            isLoading.loading === true ? (
                              <BeatLoader size={5} color="#fff" />
                            ) : (
                              "Update"
                            )}
                          </button>
                          {/* <button
                    type="button"
                    className="wid"
                    data-placement="top"
                    title="Are You Sure You Want To Delete This Information"
                    data-toggle="tooltip"
                      
                  >
                    {isLoading.action === "Delete" &&
                    isLoading.loading === true ? (
                      <BeatLoader size={5} color="#fff" />
                    ) : (
                      <Ai.AiFillDelete />
                    )}
                  </button> */}
                        </Col>
                      </Col>
                    </Row>
                  </form>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default EditTherapy;
