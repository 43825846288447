export const APIEndpoints = {
  //Auth EndPoint
  login: "admin/login",
  confirmTherapist: "admin/addinstructorpassword",

  // Therapist's EndPoint
  addTherapist: "admin/addinstructor",
  getTherapist: "admin/allinstructors",
  editTherapist: "admin/editInstructor",
  deleteTherapist: "admin/deleteinstructor",
  therapistDetails: "admin/instructordetail",
  ScheduledSessionList: "admin/getTherapistScheduledList",
  enableTherapist: "admin/enableinstructor",
  disableTherapist: "admin/disableinstructor",
  editInstructor: "admin/editInstructor",
  getClientTherapyList: "admin/gettherapistscheduledlistalpha",
  updateScheduleByTherapist: "admin/updateBookingSheduleStatus",

  // Therapy EndPoint
  addTherapy: "admin/addtherapy",
  getTherapy: "admin/alltherapies",
  editTherapy: "admin/edittherapy",
  deleteTherapy: "admin/deleteTherapy",
  scheduleTherphy: "admin/scheduletherapy",
  listScheduleTherapies: "admin/listscheduletherapies",
  getScheduledTherapyByTherapist: "admin/getTherapistScheduledList",
  disableTherapy: "admin/disabletherapy",
  enableTherapy: "admin/enabletherapy",
  makePopularize: "admin/popularize-therapy",
  pendingDateSchedule: "admin/listPendingDateSchedulesForApproval",
  approveDateSchedule: "admin/approvedateschedule",

  //Category EndPoint
  addCategory: "admin/addcategory",
  getCategory: "admin/allcategories",
  editCategory: "admin/editcategory",
  deleteCategory: "admin/deletecategory",

  //Schedule Stream
  changeScheduleStreamStatus: "admin/changebookedscheduledstreamstatus",
  scheduleTherapyByTherapist: "admin/weeklyschedule",

  //Users
  listAllRegisteredUsers: "admin/allusers",

  //Purchase
  listAllPruchases: "admin/getpurchasehistory",

  //weekly scedule
  weeklySchedule: "admin/weeklyschedule",
  getWeeklyScheduleList: "admin/getweeklyschedulelist",
  getListOfScheduledSlots: "admin/listPendingForApprovalSchedules",
  approveShceduledSlot: "admin/approveschedule/",
  createBroadcastToken: "admin/createbroadcasttoken",

  //Date Schedule
  getDateSchedules: "admin/getdateschedulelist",
  dateSchedule: "admin/dateschedule",
};
