import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import Utils from "../../Utils/Utils";
import * as Bs from "react-icons/bs";
import * as Ai from "react-icons/ai";
import { Toaster, toast } from "react-hot-toast";
import { BeatLoader } from "react-spinners";

function TherapistDashboard({ loggedInstructorDetails, navigateToProfile }) {
  const {
    therapistSchedules,
    approveScheduleByTherapist,
    getClientsScheduleData,
    scheduleLoading,
    getAllScheduledStreams,
  } = useContext(AppContext);
  // const [showOptions, setShowOptions] = useState({
  //   status: false,
  //   index: -1,
  // });
  const [loadingIndex, setLoadingIndex] = useState(-1);

  // const toggleAction = (index) => {
  //   setShowOptions({
  //     status: !showOptions.status,
  //     index: index,
  //   });
  // };

  const handleApproval = async (data, id) => {
    console.log(id);
    if (!data) return;
    setLoadingIndex(id);
    const payload = {
      therapistId: data.therapistId,
      bookingId: data._id,
      status: "accepted",
    };

    await approveScheduleByTherapist(payload, {
      onSuccess: async (res) => {
        toast.success("Booking Accepted");
        await getClientsScheduleData();
        await getAllScheduledStreams();
        console.log(res);
      },
      onFailed: (err) => {
        console.error("ERROR ON ACCEPTING A BOOKING", err);
      },
    });
  };

  return (
    <div>
      <Toaster position="top-center" />
      <section class="main ml-3">
        <h1>Welcome Therapist</h1>
        <div class="container mt-4">
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-4">
              <div class="card inst_card therapist p-4">
                <h2>Sessions</h2>
                <p>
                  Assigned Sessions: <span>5</span>
                </p>
                <p>
                  Session Details: <span>Yoga master cla...</span>
                  <i>
                    <Ai.AiFillInfoCircle />
                    <div class="tooltip-therapist">
                      <p>list of sessions</p>
                    </div>
                  </i>
                </p>
                <p>
                  Users: <span>7</span>
                </p>
                <div className="dash_icon">
                  <img src={require("../../assets/teacher.png")} alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6">
              <div class="card inst_card therapist p-4">
                <h2>Upcoming session</h2>
                <p>
                  Title: <span>Meditation</span>
                </p>
                <p>
                  User: <span>Joshua Mathew</span>
                </p>
                <p>
                  Date: <span>22/01/2023</span>
                </p>
                <p>
                  Duration <span>10:00AM - 11:00AM</span>
                </p>
                <div className="dash_icon">
                  <img src={require("../../assets/upcoming.png")} alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6">
              <div class="card inst_card therapist p-4">
                <h2>Profile</h2>
                {loggedInstructorDetails == null ? (
                  <div className="spinner-border" role="status"></div>
                ) : (
                  <>
                    <p>
                      Full Name:{" "}
                      <span>{loggedInstructorDetails?.name ?? "N/A"}</span>
                    </p>
                    <p>
                      Experience:{" "}
                      <span>
                        {loggedInstructorDetails?.experience ?? "N/A"}
                      </span>
                    </p>
                    <p>
                      Designation:{" "}
                      <span>
                        {loggedInstructorDetails?.desigination ?? "N/A"}
                      </span>
                    </p>
                    <p>
                      Phone:{" "}
                      <span>
                        {loggedInstructorDetails?.phonenumber ?? "N/A"}
                      </span>
                    </p>
                    {/* <button
                          type="button"
                          className="button-thera"
                          onClick={() =>
                            navigateToProfile(loggedInstructorDetails._id)
                          }
                        >
                        <i className=""><Ai.AiOutlineEdit/></i>
                          Edit
                        </button> */}
                    <button
                      class="mdc-button mdc-button--raised mdc-button--leading button-thera"
                      type="button"
                      className="button-thera"
                      onClick={() =>
                        navigateToProfile(loggedInstructorDetails._id)
                      }
                    >
                      <i
                        class="material-icons mdc-button__icon"
                        aria-hidden="true"
                      >
                        <Ai.AiOutlineEdit />
                      </i>
                      <span class="mdc-button__label">Edit</span>
                    </button>
                    <div class="profile_pic_therapist">
                      <img
                        src={
                          loggedInstructorDetails.photo == null
                            ? require("../../assets/placeholder.webp")
                            : process.env.REACT_APP_IMAGE_URL +
                              loggedInstructorDetails.photo
                        }
                        alt=""
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <h4 className="text-left ms-3">Client Request's</h4>
          <div class="row" style={{ overflow: "visible" }}>
            {therapistSchedules.schedules != null &&
              therapistSchedules.schedules.map((schedule, index) => {
                const users = schedule.userDetails;
                const scheduleDetails = schedule.scheduleDetails;
                // console.log(schedule);
                return (
                  <div className="col-lg-4 col-md-4 col-sm-4" key={index}>
                    <div className="card inst_card p-2">
                      <h4 className="mb-2 mt-2">
                        {schedule?.theraphyDetails?.therapyName ?? "N/A"}
                      </h4>
                      <p className="sched_date">
                        <Ai.AiTwotoneCalendar />{" "}
                        {Utils.formatDate(schedule?.date)}
                      </p>
                      <p id="time_">
                        {schedule.scheduleDetails != null
                          ? scheduleDetails?.startTime
                          : "00"}
                        -
                        {schedule.scheduleDetails != null
                          ? scheduleDetails?.endTime
                          : "00"}
                      </p>
                      <div class="user_data">
                        {users.map((user, userIndex) => {
                          return (
                            <ul className="user_list" key={userIndex}>
                              <li>
                                <p>{user.name ?? "N/A"}</p>
                                <span>{user?.email ?? "N/A"}</span>
                              </li>
                            </ul>
                          );
                        })}
                      </div>
                      <hr />
                      {schedule.scheduleStatus == "pending" ? (
                        <div class="button_grp mt-2">
                          <button
                            class="opt_ acc_"
                            onClick={() => handleApproval(schedule, index)}
                            disabled={scheduleLoading && loadingIndex == index}
                          >
                            {scheduleLoading && loadingIndex == index ? (
                              <BeatLoader color="#fff" size={10} />
                            ) : (
                              <>
                                <Bs.BsCheck2 /> <span>Accept</span>
                              </>
                            )}
                          </button>
                          {/* <button class="opt_ dec_">
                                    <Ai.AiOutlineClose />
                                  </button> */}
                        </div>
                      ) : (
                        <p className="accepted_">
                          <Bs.BsCheck2All /> Accepted
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </div>
  );
}

export default TherapistDashboard;
