import React from "react";
import Home from "../Pages/Home/Home";
import Login from "../Pages/Login/Login";
import Instructors from "../Pages/Instructors/Instructors";
import Categories from "../Pages/Category/Category";
import Lives from "../Pages/Lives/Lives";
import Settings from "../Pages/Settings/Settings";
import ScheduleManagement from "../Pages/Schedule/ScheduleManagement";
import Users from "../Pages/Users/Users";
import Purchases from "../Pages/Purchases/Purchases";
import AddInstructors from "../Pages/Instructors/AddInstructors";
import Therapy from "../Pages/Therapy/Therapy";
import SubCategories from "../Pages/SubCategories/Subcategories";
import EditTherapist from "../Pages/Instructors/EditTherapist";
import ProfileEdit from "../Pages/Home/ProfileEdit";
import TherapistLogin from "../Pages/Login/TherapistLogin";
import StreamManager from "../Pages/streamManager/StreamManager";
import TherapistAddScheduleTherapy from "../Pages/Schedule/TherapistAddScheduleTherapy";
import ScheduleAction from "../Pages/Schedule/ScheduleAction";
import LiveSession from "../Components/LiveSession/LiveSession";
import EditTherapy from "../Pages/Therapy/EditTherapy";
import EditCategory from "../Pages/Category/EditCategory";
import RedirectError from "../Pages/RedirectError/RedirectError";
import PageNotFound from "../Pages/RedirectError/PageNotFound";

const AppRoutes = [
  {
    isProtected: false,
    isProtectedAuth: true,
    path: "/login",
    element: <Login />,
  },
  {
    isProtected: false,
    isProtectedAuth: true,
    path: "admin/therapist/create/:token",
    element: <TherapistLogin />,
  },
  {
    isProtected: true,
    path: "/",
    element: <Home role={["superadmin", "instructor"]} />,
  },
  {
    isProtected: true,
    path: "/therapisthome",
    element: <Home />,
  },
  {
    isProtected: true,
    path: "/therapist",
    element: <Instructors role={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/therapy",
    element: <Therapy role={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/category",
    element: <Categories role={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/subcategories",
    element: <SubCategories role={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/lives",
    element: <Lives role={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/settings",
    element: <Settings role={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/client",
    element: <Users role={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/purchases",
    element: <Purchases role={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/schedule",
    element: <ScheduleManagement role={["superadmin", "instructor"]} />,
  },
  {
    isProtected: true,
    path: "/add-therapist",
    element: <AddInstructors role={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/edit-therapist/:id",
    element: <EditTherapist role={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/session-manager",
    element: <StreamManager role={["instructor"]} />,
  },
  {
    isProtected: true,
    path: "/profile-instructor/:id",
    element: <ProfileEdit role={["instructor"]} />,
  },
  {
    isProtected: true,
    path: "/add-schedule-therapist/:id",
    element: <TherapistAddScheduleTherapy role={["instructor"]} />,
  },
  {
    isProtected: true,
    path: "/schedule-approve/:id",
    element: <ScheduleAction role={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/therapy-session/:id",
    element: <LiveSession role={["instructor"]} />,
  },
  {
    isProtected: true,
    path: "/edit-therapy/:id",
    element: <EditTherapy role={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/edit-category/:id",
    element: <EditCategory role={["superadmin"]} />,
  },
  { isProtected: true, path: "/restricted-entry", element: <RedirectError /> },
  {
    isProtected: false,
    isProtectedAuth: false,
    path: "*",
    element: <PageNotFound />,
  },
];

export default AppRoutes;
