import { React, useState, useContext, useEffect } from "react";
import { Row, Col, Table, Container } from "react-bootstrap";
import * as Bs from "react-icons/bs";
import * as Ai from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import "./Category.css";
import { BeatLoader } from "react-spinners";
import Header from "../../Components/Header/Header";
import useCategory from "../../hook/useCategory";
import { AppContext } from "../../context/AppContext";
import EditModal from "./EditModal";
import toast, { Toaster } from "react-hot-toast";
import ReactPaginate from "react-paginate";

function Category() {
  const navigate = useNavigate();
  const { categories, getCategory, addCategory, categoryLoading } =
    useContext(AppContext);
  const [category, setCategory] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const categoryItems = categories.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    console.log(categories);
  }, []);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const onClickShowModal = () => {
    setShowModal(true);
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    if (category.length === 0 || categoryDescription.length === 0) {
      setError(true);
      return false;
    }
    const payload = {
      category: category,
      categoryDescription: categoryDescription,
    };

    await addCategory(payload, {
      onSuccess: (res) => {
        getCategory();
        toast.success("Added Successfully!", {
          style: {
            backgroundColor: "#fff",
            padding: "16px",
            color: "#252525",
          },
        });
        setTimeout(() => {
          setShowModal(false);
          setCategory("");
          setCategoryDescription("");
        }, 1000);
      },
      onFailed: (e) => console.log("failed", e),
    });
  };
const closeModal = () => {
  setShowModal(false);
  setCategory("");
  setCategoryDescription(""); 
}
  return (
    <>
      <Toaster position="top-center" />
      <header>
        <Header />
      </header>
      <div className={`modal_bg ${!showModal ? "cat" : "cat show"}`}>
        <div className="add_modal mode-cat">
          <i className="close" onClick={() =>closeModal()}>
            <Ai.AiFillCloseCircle />
          </i>
          <h2 className="text-center">Add Category</h2>
          <form
            className="form-control add_form"
            onSubmit={(e) => formSubmit(e)}
          >
            <Row>
              <Col>
                <div className="form-group">
                  <label for="email">Category Name</label>
                  <input
                    type="text"
                    onChange={(e) => setCategory(e.target.value)}
                    value={category}
                    className="form-control"
                    name="category"
                  />
                  {error && category?.length === 0 ? (
                    <>
                      <span style={{ color: "red" }}>Please add Category</span>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <div className="form-group">
                <label for="email">Category Description</label>
                <textarea
                  onChange={(e) => setCategoryDescription(e.target.value)}
                  value={categoryDescription}
                  name="categoryDescription"
                  className="category"
                />
              </div>
            </Row>
            <br />
            <Row>
              <br />
              <button type="submit" className="form_btn">
                {categoryLoading.action == "Add" &&
                categoryLoading.loading == true ? (
                  <BeatLoader color="#fff" />
                ) : (
                  "Add"
                )}
              </button>
            </Row>
          </form>
        </div>
      </div>
      <main>
        <section className="main">
          <button className="add_btn mt-3" onClick={onClickShowModal}>
            +Add
          </button>
          <h2 className="text-center">Categories</h2>

          <Container className="containers table-co">
            <div className="card inst_card mt-3 table-responsive" id="cat_card">
              <Table>
                <table role="table" className="table table-bordered">
                  <thead style={{ textAlign: "center" }}>
                    <tr>
                      <th>#</th>
                      <th>Category Name</th>
                      <th>Category Description</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {categoryItems.map((category, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{category?.category}</td>
                          <td>{category?.categoryDescription ?? "N/A"}</td>
                          <td>
                            <button
                              className="btn eye-btn btn-success"
                              onClick={() => {
                                navigate(`/edit-category/${category._id}`);
                              }}
                              type="button"
                            >
                              <i>
                                <Bs.BsFillPencilFill />
                              </i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Table>
            </div>
            <div className="pagnte">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={Math.ceil(categories.length / itemsPerPage)}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
                disabledClassName={"disabled"}
                activeClassName={"paging__link--active"}
              />
            </div>
          </Container>
        </section>
      </main>
    </>
  );
}

export default Category;
