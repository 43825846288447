import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Header from "../../Components/Header/Header";
import { AppContext } from "../../context/AppContext";
import ScheduleModal from "./ScheduleModal";
import useCookies from "../../hook/useCookies";
import ScheduleManagementTherapist from "./ScheduleManagementTherapist";
import * as Ai from "react-icons/ai";
import "./ScheduleManagement.css";
import { Row, Col, Container } from "react-bootstrap";
import ReactPaginate from "react-paginate";

function ScheduleManagement() {
  const navigate = useNavigate();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { scheduledTheraphies, scheduledSlot, programs } =
    useContext(AppContext);
  const [isRole, setIsRole] = useState(null);

  const { getValueFromCookies } = useCookies({ key: "UserPersistent" });

  useEffect(() => {
    const { role } = getValueFromCookies();
    setIsRole(role);
  }, []);


  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const ItemsSchedule = scheduledSlot.slice(indexOfFirstItem, indexOfLastItem);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <>
      <Header />
      <ScheduleModal
        open={openAddModal}
        onClose={() => setOpenAddModal(false)}
      />
      <main>
        <section class="main">
          {isRole === "superadmin" && (
            <>
              {/* <button className="add_btn" onClick={() => setOpenAddModal(true)}>
                + Add
              </button> */}
              <h1>Schedule Management</h1>
              <div class="card div-manage">
                <table
                  class="table table-bordered"
                  style={{ textAlign: "center" }}
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Therapist</th>
                      <th>Desigination</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ItemsSchedule !== null &&
                      Array.isArray(ItemsSchedule) &&
                      ItemsSchedule.map((schedule, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{schedule?.therapistDetails.name ?? "N/A"}</td>
                            <td>
                              {schedule?.therapistDetails.desigination ?? "N/A"}
                            </td>
                            <td>
                              <button
                                className="view-btn"
                                onClick={() =>
                                  navigate(`/schedule-approve/${schedule._id}`)
                                }
                              >
                                <Ai.AiFillEye />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </>
          )}
          {isRole === "instructor" && (
            <>
              <ScheduleManagementTherapist />
            </>
          )}
          <div className="pagnte">
            {/* <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={Math.ceil(scheduledSlot.length / itemsPerPage)}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              previousLinkClassName={"page-link"}
              nextLinkClassName={"page-link"}
              disabledClassName={"disabled"}
              activeClassName={"paging__link--active"}
            /> */}
          </div>
        </section>
      </main>
    </>
  );
}

export default ScheduleManagement;
