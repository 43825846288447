import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import Header from "../../Components/Header/Header";
import { Row, Col, Container } from "react-bootstrap";
import * as Ai from "react-icons/ai";
import * as Bs from "react-icons/bs";
import * as Md from "react-icons/md";
import * as Sl from "react-icons/sl";
import "./ScheduleManagement.css";
import Card from "../../Components/Card/Card";
import { AppContext } from "../../context/AppContext";
import toast, { Toaster } from "react-hot-toast";
import ScheduleData from "./ScheduleData";

function ScheduleAction() {
  const navigate = useNavigate();
  const params = useParams();
  const { scheduledTheraphies, scheduledSlot, programs, approveScheduledSlot } =
    useContext(AppContext);
  const [slotSchedule, setSlotSchedule] = useState([]);

  useEffect(() => {
    if (Array.isArray(scheduledSlot) && scheduledSlot.length != 0) {
      const locSchedule = scheduledSlot.find(
        (schedule) => schedule._id == params.id
      );
      setSlotSchedule(locSchedule);
    }
  }, [scheduledSlot]);

  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const ApproveTherapy = async(scheduleId, slotId, day) => {
    await approveScheduledSlot(scheduleId, slotId, day, {
      onSuccess: () => {
        toast.success("Schedule is Approved SuccessFully");
      },
      onFailed: () => {
        toast.error("Approve Failed");
      },
    });
  };
  return (
    <>
      <Toaster position="top-center" />
      <Header />
      <main>
        <section class="main">
          <button className="back_btn" onClick={() => navigate("/schedule")}>
            <Ai.AiOutlineLeft />
          </button>
          <h2>Schedule Action</h2>
          <Container>
            <div class="card schedule_card_">
              <h5>Therapist Details:</h5>
              <p>
                Name:{" "}
                <span>{slotSchedule?.therapistDetails?.name ?? "N/A"}</span>
              </p>
              <p>
                Designation:{" "}
                <span>
                  {slotSchedule.therapistDetails?.desigination ?? "N/A"}
                </span>
              </p>
              <p>
                Phone:{" "}
                <span>
                  {slotSchedule.therapistDetails?.phonenumber ?? "N/A"}
                </span>
              </p>
              <p>
                Email:{" "}
                <span style={{fontSize:"12px"}}>{slotSchedule.therapistDetails?.email ?? "N/A"}</span>
              </p>
            </div>
          </Container>
          {/* {slotSchedule &&
            weekDays.map((day) => {
              return (
                Array.isArray(slotSchedule[day]) &&
                slotSchedule[day].length != 0 &&
                slotSchedule[day].map((slot) => {
                  console.log(slot);
                  const therapyData = programs.find(
                    (program) => program._id == slot.therapyId
                  );

                  console.log(therapyData, "therapyData");
                  return (
                    <>
                      <div class="card schedule_card schedule_details_card mt-3">
                        <Row>
                          <Col xl={3} className="mb-2">
                            <p>{day}</p>
                            <label style={{ fontWeight: "500" }}>Time</label>
                            <div className="icon_div">
                              <div className="det d-flex">
                                <span>
                                  <p>{slot.endTime}</p>
                                </span>
                                <span>to</span>
                                <span>
                                  <p>{slot.startTime}</p>
                                </span>
                              </div>
                            </div>
                            <div className="det">
                              <label for="" style={{ fontWeight: "500" }}>
                                Price
                              </label>
                              <span>
                                <p>{slot.price} £</p>
                              </span>
                            </div>
                          </Col>
                          <Col xl={6}>
                            <p style={{ fontWeight: "500" }}>
                              {therapyData.therapyName}{" "}
                            </p>
                            <span>{therapyData.therapyDescription}</span>
                          </Col>
                          <Col xl={3} className="mb-3">
                            <div className="row mt-3">
                              <button
                                class="action_btn approve mb-3 mx-auto"
                                onClick={() =>
                                  ApproveTherapy(slotSchedule._id, slot._id, day)
                                }
                              >
                                <Bs.BsCheckAll /> Approve
                              </button>
                            </div>
                            <div className="row">
                              <button class="action_btn deny mx-auto">
                                <Md.MdOutlineClose />
                                Deny
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </>
                  );
                })
              );
            })} */}
            <ScheduleData />
        </section>
      </main>
    </>
  );
}

export default ScheduleAction;
