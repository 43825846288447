/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { Button } from "antd";
import Avatar from "react-avatar-edit";
import { Dialog } from "primereact/dialog";
import * as Ai from "react-icons/ai";
import toast, { Toaster } from "react-hot-toast";
import "./Instructors.css";
import "antd/dist/reset.css";
import Profile from "../../assets/placeholder.png";
import { AppContext } from "../../context/AppContext";
import Header from "../../Components/Header/Header";
import { BeatLoader } from "react-spinners";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Utils from "../../Utils/Utils";

function EditTherapist() {
  const params = useParams();
  const navigate = useNavigate();
  const {
    therapists,
    deleteTherapist,
    getTherapist,
    editTherapist,
    actionLoading,
  } = useContext(AppContext);
  const [editInstructorDetails, setEditInstructorDetails] = useState({});
  const [therapist, setTherapist] = useState(null);
  const [dialogues, setDialogues] = useState(false);
  const [file, setFile] = useState(null);
  const [formErrors, setFormErrors] = useState("");

  useEffect(() => {
    findTherapistFromList();

    console.log(therapists);
  }, [therapists]);

  useEffect(() => {
    console.log("editInstructorDetails", editInstructorDetails);
  }, [editInstructorDetails]);

  useEffect(() => {
    if (therapist != null) {
      setEditInstructorDetails(therapist);
    }
  }, [therapist]);
  const findTherapistFromList = () => {
    if (!params.id) return;
    const locTherpist = therapists.find((x) => x._id === params.id);
    // console.log("locTherpist", locTherpist);
    setTherapist(locTherpist);
  };
  useEffect(() => {
    validator(editInstructorDetails);
  }, [editInstructorDetails]);

  const onChangeEditingField = ({ name, value }) => {
    if (name.length == 0) return;
    let EditInstDetails = editInstructorDetails;
    EditInstDetails[name] = value;
    setEditInstructorDetails({ ...EditInstDetails });
  };
  const editTherapistSingle = (e) => {
    e.preventDefault();
    const errors = validator(editInstructorDetails);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      delete editInstructorDetails._id;
      const bodyFormData = Utils.createFormDataFromFormValues({
        ...editInstructorDetails,
      });
      if (file != null) {
        bodyFormData.append("file", Utils.createBlobFile(file));
      }

      console.log(bodyFormData.getAll("bio"), "editInstructorDetails");
      // debugger;
      const id = params.id;

      editTherapist(bodyFormData, id, {
        onSuccess: async (res) => {
          console.log(res);
          await getTherapist();
          toast.success("Edited Successfully!", {
            style: {
              backgroundColor: "#fff",
              padding: "16px",
              color: "#252525",
            },
          });
          setTimeout(() => {
            navigate("/therapist");
          }, 1000);
        },
        onFailed: (err) => console.log(err),
      });
    }
  };
  const validator = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "This field cannot be blank";
    }
    if (!values.email) {
      errors.email = "This field cannot be blank";
    }
    if (!values.phonenumber) {
      errors.phonenumber = "This field cannot be blank";
    }
    if (!values.profession) {
      errors.profession = "This field cannot be blank";
    }
    if (!values.desigination) {
      errors.desigination = "This field cannot be blank";
    }
    if (!values.experience) {
      errors.experience = "This field cannot be blank";
    }
    if (!values.bio && values.bio !== null) {
      errors.bio = "This field cannot be blank";
    }
    return errors;
  };
  const handleDeleteTherapist = async (id) => {
    confirmAlert({
      title: "Are you sure?",
      buttons: [
        {
          className: "confirm-btn",
          label: "Yes",
          onClick: () => {
            deleteTherapist(id, {
              onSuccess: async () => {
                await getTherapist();
                toast.success("deleted Successfully!", {
                  style: {
                    backgroundColor: "#fff",
                    padding: "16px",
                    color: "#252525",
                  },
                });
                setTimeout(() => {
                  navigate("/therapist");
                }, 1000);
              },
              onFailed: (err) => {
                console.log(err);
              },
            });
          },
        },
        {
          className: "clear-button",
          label: "No",
        },
      ],
    });
  };

  const onCrop = (view) => {
    setFile(view);
  };

  const closeCropModal = () => {
    setDialogues(false);
  };

  const navigateBack = async () => {
    navigate(-1);
    await getTherapist();
  };

  return (
    <div>
      <Toaster position="top-center" />
      <Header />

      <Container fluid="md">
        <main>
          <section className="main">
            <button className="back_btn" onClick={() => navigateBack()}>
              <Ai.AiOutlineLeft />
            </button>
            <h2>Edit Therapist</h2>
            <form
              className="form-control add_form"
              onSubmit={(e) => editTherapistSingle(e)}
            >
              <Row>
                <Col>
                  <div className="profile">
                    <div className="flex flex-column justify-content-center align-items-center">
                      <img
                        // src={file != null ? file : Profile}
                        src={
                          file != null
                            ? file
                            : editInstructorDetails.photo != null
                            ? process.env.REACT_APP_IMAGE_URL +
                              editInstructorDetails.photo
                            : Profile
                        }
                        alt=""
                        style={{ maxWidth: "100%" }}
                        onClick={() => {
                          setDialogues(true);
                        }}
                      />
                      <Dialog
                        visible={dialogues}
                        header={() => (
                          <>
                            <p>Update Profile Picture</p>
                          </>
                        )}
                        onHide={() => setDialogues(false)}
                      >
                        <div className="confirmation-content flex flex-column align-items-center">
                          <div className="flex flex-column align-items-center mt-5 w-12">
                            <div className="flex flex-column justify-content-around w-12 mt-4">
                              <Avatar
                                width={400}
                                height={300}
                                onClose={closeCropModal}
                                onCrop={onCrop}
                              />
                              <Button
                                label="Save"
                                onClick={closeCropModal}
                                className="mt-4"
                              >
                                Save
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Dialog>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div class="form-group">
                    <label for="name" className="text-nowrap">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={editInstructorDetails.firstName}
                      onChange={(e) => onChangeEditingField(e.target)}
                    ></input>
                    {formErrors.name && !editInstructorDetails.name && (
                      <p className="red">{formErrors.name}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div class="form-group">
                    <label for="name" className="text-nowrap">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={editInstructorDetails.lastName}
                      onChange={(e) => onChangeEditingField(e.target)}
                    ></input>
                    {formErrors.name && !editInstructorDetails.name && (
                      <p className="red">{formErrors.name}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div class="form-group">
                    <label for="email">Email</label>
                    <input
                      name="email"
                      type="email"
                      className="form-control"
                      value={editInstructorDetails.email}
                      onChange={(e) => onChangeEditingField(e.target)}
                    />
                    {formErrors.email && !editInstructorDetails.email && (
                      <p className="red">{formErrors.email}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 mt-3">
                  <div className="form-group">
                    <label for="phone">Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      name="phonenumber"
                      value={editInstructorDetails.phonenumber}
                      onChange={(e) => onChangeEditingField(e.target)}
                    />
                    {formErrors.phonenumber &&
                      !editInstructorDetails.phonenumber && (
                        <p className="red">{formErrors.phonenumber}</p>
                      )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 mt-3">
                  <label>Profession</label>
                  <input
                    type="text"
                    name="profession"
                    className="form-control mb-3"
                    value={editInstructorDetails.profession}
                    onChange={(e) => onChangeEditingField(e.target)}
                  />
                  {formErrors.profession &&
                    !editInstructorDetails.profession && (
                      <p className="red">{formErrors.profession}</p>
                    )}
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 mt-3">
                  <label for="">Designation</label>
                  <input
                    type="text"
                    name="desigination"
                    className="form-control"
                    value={editInstructorDetails.desigination}
                    onChange={(e) => onChangeEditingField(e.target)}
                  />
                  {formErrors.desigination &&
                    !editInstructorDetails.desigination && (
                      <p className="red">{formErrors.desigination}</p>
                    )}
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <label for="">Experience</label>
                  <input
                    type="text"
                    name="experience"
                    className="form-control"
                    value={editInstructorDetails.experience}
                    onChange={(e) => onChangeEditingField(e.target)}
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <label for="">Bio</label>
                  <input
                    type="text"
                    name="bio"
                    className="form-control"
                    value={editInstructorDetails.bio}
                    onChange={(e) => onChangeEditingField(e.target)}
                  />
                  {formErrors.bio && !editInstructorDetails.bio && (
                    <p className="red">{formErrors.bio}</p>
                  )}
                </div>
              </Row>
              <Row className="mt-3">
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <label for="Weekly Price">Session Price</label>
                  <input
                    name="price"
                    type="text"
                    className="form-control"
                    value={editInstructorDetails?.price}
                    onChange={(e) => onChangeEditingField(e.target)}
                  />
                </div>
              </Row>
              {/* SocialMedia Icon */}
              {/* <Row>
                <Col className="text-center">
                  <i className="socialmedia">
                    <a href="" className="icon_socialmedia">
                      <Ai.AiFillFacebook />
                    </a>
                    <a href="" className="icon_socialmedia">
                      <Ai.AiFillTwitterSquare />
                    </a>
                    <a href="" className="icon_socialmedia">
                      <Ai.AiFillInstagram />
                    </a>
                    <a href="" className="icon_socialmedia">
                      <Ai.AiFillLinkedin />
                    </a>
                  </i>
                </Col>
              </Row> */}
              <Row className="buttons">
                <Col className="text-center">
                  <Col className="mx-auto">
                    <button type="submit" className="edit_btn">
                      {actionLoading.action === "Edit" &&
                      actionLoading.loading === true ? (
                        <BeatLoader color="#fff" />
                      ) : (
                        "Update"
                      )}
                    </button>
                    {/* <button
                      type="button"
                      className="wid"
                      data-placement="top"
                      onClick={() => handleDeleteTherapist(params.id)}
                    >
                      {actionLoading.action === "Delete" &&
                      actionLoading.loading === true ? (
                        <BeatLoader size={5} color="#fff" />
                      ) : (
                        <Ai.AiFillDelete />
                      )}
                    </button> */}
                  </Col>
                </Col>
              </Row>
            </form>
          </section>
        </main>
      </Container>
    </div>
  );
}

export default EditTherapist;
