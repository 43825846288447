import React, { useContext, useEffect, useState } from "react";
import * as Bs from "react-icons/bs";
import { AppContext } from "../../context/AppContext";
import "./ScheduleData.css";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import SchedulePendingList from "./SchedulePendingList";
import { Modal } from "antd";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import DateSchedulePendingList from "./DateSchedulePendingList";

function ScheduleData() {
  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const params = useParams();

  const [slotSchedule, setSlotSchedule] = useState([]);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [currentSchedule, setCurrentSchedule] = useState([]);
  const [therapyData, setTherapyData] = useState([]);
  const [price, setPrice] = useState("");
  const [error, setError] = useState(false);

  const { scheduledTheraphies, scheduledSlot, programs, approveScheduledSlot, dateScheduleList } =
    useContext(AppContext);

  useEffect(() => {
    if (Array.isArray(scheduledSlot) && scheduledSlot.length != 0) {
      const locSchedule = scheduledSlot.find(
        (schedule) => schedule._id == params.id
      );
      setSlotSchedule(locSchedule);

    }
  }, [scheduledSlot]);

  useEffect(() => {
    getTherapyById();
    // console.log("currentSchedule", currentSchedule);
  }, [currentSchedule]);


  const getTherapyById = () => {
    programs.map((therapy) => {
      if (therapy._id == currentSchedule.therapyId) {
        setTherapyData(therapy);
      }
    });
  };

  const approveTherapy = async () => {
    if (!price) {
      setError(true);
      return;
    }
    setError(false);
    const scheduleId = currentSchedule.scheduleId;
    const slotId = currentSchedule._id;
    const day = currentSchedule.day;
    await approveScheduledSlot(scheduleId, slotId, day, price, {
      onSuccess: () => {
        toast.success("Schedule is Approved SuccessFully");
        setTimeout(() => {
          setPrice("");
          setShowScheduleModal(false);
        }, 2000);
      },
      onFailed: (error) => {
        toast.error("Approve Failed");
        console.log(error);
      },
    });
  };
  return (
    <div>
      <Modal
        open={showScheduleModal}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => {
          setShowScheduleModal(false);
          setPrice("");
          setError(false);
        }}
      >
        <h3>Add Price to Schedule</h3>
        <p>
          Therapy Name: <span>{therapyData.therapyName}</span>
        </p>
        <p>
          Price:{" "}
          <input
            type="text"
            className="price form-control"
            value={price}
            placeholder={"Enter price"}
            onChange={(e) => setPrice(e.target.value)}
          />
          {error ? (
            <span style={{ color: "red" }}>Price connot be blank</span>
          ) : (
            ""
          )}
        </p>
        <button class="approve_btn" onClick={approveTherapy}>
          Approve
        </button>
        {/* <p>Therapist: }</p> */}
      </Modal>
      <div class="container-class mt-3">
        <h2 className="headtwo ml-3">Schedules</h2>
        <Tabs
          defaultActiveKey="All"
          id="justify-tab-example"
          className="mb-3"
          justify
        >
          <Tab eventKey="All" title="Weekly Schedules">
            <ul class="cards-data mb-3">
              {weekDays.map((week) => {
                return (
                  <SchedulePendingList
                    scheduleList={slotSchedule[week]}
                    weekName={week}
                    slotSchedule={slotSchedule}
                    onClickApprove={(schedule) => {
                      setShowScheduleModal(true);
                      setCurrentSchedule({
                        scheduleId: slotSchedule._id,
                        ...schedule,
                        day: week,
                      });
                    }}
                  />
                );
              })}
            </ul>
          </Tab>
          <Tab eventKey="daily" title="Date-Wise Schedules">
            <DateSchedulePendingList/>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default ScheduleData;
