import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Ai from "react-icons/ai";
import { Row, Col } from "react-bootstrap";
import "./Category.css";
import { AppContext } from "../../context/AppContext";
import { BeatLoader } from "react-spinners";
import toast, { Toaster } from "react-hot-toast";
import Header from "../../Components/Header/Header";

function EditCategory() {
  const params = useParams();
  const navigate = useNavigate();
  const {
    deleteCategory,
    categoryLoading,
    categories,
    getCategory,
    editCategory,
  } = useContext(AppContext);
  const [categoryName, setCategoryName] = useState(null);
  const [categoryDesc, setCategoryDesc] = useState(null);
  const [category, setCategory] = useState(null);
  useEffect(() => {
    findCategory();
  }, []);
  useEffect(() => {
    initFunction();
  }, [category]);
  const findCategory = () => {
    if (!params.id) return;
    const locCategory = categories.find((x) => x._id === params.id);
    // console.log("locTherpist", locTherpist);
    setCategory(locCategory);
  };
  const initFunction = () => {
    if (!category) return;
    setCategoryName(category.category);
    setCategoryDesc(category.categoryDescription);
  };

  const editCategorySingle = (e) => {
    e.preventDefault();

    const payload = {
      category: categoryName,
      categoryDescription: categoryDesc,
    };


    editCategory(payload, params.id, {
      onSuccess: async (res) => {
        await getCategory();
        toast.success("Edited Successfully!", {
          style: {
            backgroundColor: "#fff",
            padding: "16px",
            color: "#252525",
          },
        });
        setTimeout(() => {
          setCategory("");
          setCategoryDesc("")
          navigate("/category");
        }, 1000);
      },
      onFailed: (err) => console.log("error", err),
    });
  };

  // const deleteCategorySingle = (id) => {
  //   deleteCategory(id, {
  //     onSuccess: () => {
  //       toast.success("deleted Successfully!", {
  //         style: {
  //           backgroundColor: "#fff",
  //           padding: "16px",
  //           color: "#252525",
  //         },
  //       });
  //       setTimeout(() => {
  //         onClose(false);
  //       }, 1000);
  //     },
  //     onFailed: (err) => console.log(err),
  //   });
  // };

  return (
    <>
      <Toaster position="top-center" />
      <Header />
      <main>
        <section class="main">
          <div>
            <button
              className="back_btn ms-4"
              style={{ position: "absolute" }}
              onClick={() => navigate(-1)}
            >
              {" "}
              <Ai.AiOutlineLeft />
            </button>
            <div className="container edit-form">
              <h2 className="text-center mb-3">Edit Category</h2>
              <form
                className="form-control add_form modal_content"
                onSubmit={(e) => editCategorySingle(e)}
              >
                <Row>
                  <Col>
                    <div className="form-group">
                      <label for="email">Category Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="category"
                        value={categoryName}
                        onChange={(e) => setCategoryName(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form-group">
                      <label for="email">Category Description</label>
                      <textarea
                        name="categoryDescription"
                        className="category"
                        value={categoryDesc}
                        onChange={(e) => setCategoryDesc(e.target.value)}
                      ></textarea>
                    </div>
                  </Col>
                </Row>

                <button type="submit" className="edit_btn">
                  {categoryLoading.action === "Edit" &&
                  categoryLoading.loading === true ? (
                    <BeatLoader color="#fff" />
                  ) : (
                    "Update"
                  )}
                </button>
                {/* <button
                    type="button"
                    className="wid"
                    data-placement="top"
                    title="Are You Sure You Want To Delete This Information"
                    data-toggle="tooltip"
                    onClick={() => deleteCategorySingle(categoryData._id)}
                  >
                    {categoryLoading.action === "Delete" &&
                    categoryLoading.loading === true ? (
                      <BeatLoader size={5} color="#fff" />
                    ) : (
                      <Ai.AiFillDelete />
                    )}
                  </button> */}
              </form>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default EditCategory;
