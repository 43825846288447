import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { DatePicker, TimePicker, Space } from "antd";
import toast, { Toaster } from "react-hot-toast";
import dayjs from "dayjs";
import * as Bs from "react-icons/bs";

function DateSchedule({ selectedDate }) {
  const params = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(false);

  // Intial Value for Schedule
  const initialValues = {
    startTime: null,
    endTime: null,
    startTimeValue: null,
    endTimeValue: null,
    therapyId: null,
    description: null,
  };

  const [scheduleSlots, setScheduleSlots] = useState([initialValues]);

  const { programs, scheduleLoading, scheduleByDate, getDateScheduleData } = useContext(AppContext);

  // New schedule Add
  const addNewSchedule = () => {
    setScheduleSlots([...scheduleSlots, initialValues]);
  };

  // Delete a Schedule
  const deleteSchedule = (deleteIndex) => {
    const slots = [];
    scheduleSlots.forEach((slot, index) => {
      if (index != deleteIndex) {
        slots.push(slot);
      }
    });
    setScheduleSlots(slots);
  };

  const onChangeTimePicker = (time, timeString, index, type) => {
    switch (type) {
      case "startTime":
        console.log("timeString", timeString);
        scheduleSlots[index].startTime = timeString;
        scheduleSlots[index].startTimeValue = time;
        setScheduleSlots([...scheduleSlots]);
        return;
      case "endTime":
        scheduleSlots[index].endTime = timeString;
        scheduleSlots[index].endTimeValue = time;
        setScheduleSlots([...scheduleSlots]);
        return;
      default:
        return;
    }
  };

  const onChangeDescription = (index, value) => {
    scheduleSlots[index].description = value;
    setScheduleSlots(scheduleSlots);
  };

  const handleDateSchedule = async (e) => {
    e.preventDefault();

    if (selectedDate.length == 0) {
      toast.error("Please Select a Date");
      return;
    }

    const id = params.id;
    const payload = {
      date: selectedDate,
      therapistId: id,
      scheduleDetails: [...scheduleSlots],
    };

    await scheduleByDate(payload, {
      onSuccess: async(res) => {
        toast.success(res.message);
        await getDateScheduleData();
        setTimeout(() => {
          navigate("/schedule");
        }, 1500);
      },
      onFailed: (err) => {
        console.log(err.message);
        toast.error(err.message);
      },
    });
  };

  return (
    <>
      <form onSubmit={(e) => handleDateSchedule(e)}>
        {scheduleSlots.map((schedule, index) => {
          return (
            <div class="card inst_card schedule_card" key={index}>
              <h5> #{index + 1}</h5>
              <div class="row">
                <div class="col-lg-4 col-sm-4 col-md-4">
                  <div class="form-group">
                    <label for="">Therapy</label>
                    <select
                      name="therapyId"
                      id=""
                      class="form-select"
                      value={initialValues.therapyId}
                      onChange={(e) => {
                        setScheduleSlots((preValue) =>
                          [...preValue].map((x) =>
                            x.therapyId === initialValues.therapyId
                              ? { ...x, therapyId: e.target.value }
                              : x
                          )
                        );
                      }}
                    >
                      <option value="0" selected>
                        Select Therapy
                      </option>
                      {programs.length > 0 &&
                        programs.map((therapy, index) => {
                          // console.log(therapy);
                          return (
                            <>
                              <option value={therapy._id}>
                                {therapy.therapyName}
                              </option>
                            </>
                          );
                        })}
                    </select>
                    {error && initialValues.therapyId === null ? (
                      <span id="err">Please Select a therapy</span>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* <div class="form-group mt-2">
                        <label for="">Price</label>
                        <input
                          type="text"
                          name=""
                          id=""
                          class="form-control details"
                          placeholder="Enter price details"
                          onChange={(e) => {
                            const value = e.target.value;
                            onchangePrice(index, value);
                          }}
                          value={initialValues.price}
                          required
                        />
                        {error && initialValues.price?.length === 0 ? (
                          <>
                            <span style={{ color: "red" }}>
                              Please add therapyname
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div> */}
                </div>
                <div class="col-lg-4 col-sm-4 col-md-4">
                  <div class="form-group ">
                    <label for="">Start Time</label>
                    <Space
                      direction="vertical"
                      style={{
                        width: "100%",
                        borderColor: "transparent",
                      }}
                    >
                      <TimePicker
                        use12Hours
                        format="h:mm A"
                        onChange={(time, timeString) =>
                          onChangeTimePicker(
                            time,
                            timeString,
                            index,
                            "startTime"
                          )
                        }
                        defaultOpenValue={dayjs("00:00", "HH:mm")}
                        value={schedule.startTimeValue}
                        style={{
                          width: "100%",
                          borderColor: "transparent",
                          outline: "none",
                          boxShadow: "0 0 0 transparent",
                        }}
                        className="form-control"
                        placeholder="Select Start Time"
                      />
                    </Space>
                    {error &&
                      initialValues.startTime == null &&
                      initialValues.startTimeValue == null && (
                        <span id="err">Please Set the Start Time</span>
                      )}
                  </div>
                  <div class="form-group mt-2">
                    <label for="">End Time</label>
                    <Space
                      direction="vertical"
                      style={{
                        width: "100%",
                        borderColor: "transparent",
                      }}
                    >
                      <TimePicker
                        use12Hours
                        format="h:mm A"
                        onChange={(time, timeString) =>
                          onChangeTimePicker(time, timeString, index, "endTime")
                        }
                        defaultOpenValue={dayjs("00:00", "HH:mm")}
                        value={schedule.endTimeValue}
                        style={{
                          width: "100%",
                          borderColor: "transparent",
                          outline: "none",
                          boxShadow: "0 0 0 transparent",
                        }}
                        className="form-control"
                      />
                    </Space>
                    {error &&
                      initialValues.endTime == null &&
                      initialValues.endTimeValue == null && (
                        <span id="err">Please Set the End Time</span>
                      )}
                  </div>
                </div>
                <div class="col-lg-4 col-sm-4 col-md-4">
                  <label for="">Description</label>
                  <textarea
                    className="form-control"
                    name=""
                    id=""
                    rows="4"
                    onChange={(e) => {
                      const value = e.target.value;
                      onChangeDescription(index, value);
                    }}
                    value={schedule.description}
                  ></textarea>
                </div>
              </div>
              {index !== 0 && scheduleSlots.length > 1 && (
                <button
                  class="delete_schedule"
                  onClick={() => deleteSchedule(index)}
                >
                  <Bs.BsFillTrashFill />
                </button>
              )}
            </div>
          );
        })}

        <button
          type="button"
          class="add_schedule"
          onClick={() => addNewSchedule()}
        >
          +
        </button>

        <button type="submit" className="form_btn mb-4">
          {scheduleLoading.action == "Add" && scheduleLoading.loading == true}
          Add
        </button>
      </form>
    </>
  );
}

export default DateSchedule;
