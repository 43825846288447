import { useState } from "react";
import BaseClient from "../Helper/BaseClient";
import { APIEndpoints } from "../constants/APIEndpoints";

const usePrograms = () => {
  const [programs, setPrograms] = useState([]);
  const [scheduledTheraphies, setScheduledTheraphies] = useState([]);
  const [sortedScheduledTherapy, setSortedScheduledTherapy] = useState([]);
  const [isLoading, setIsLoading] = useState({
    action: "",
    loading: false,
  });
  const [popularLoading, setPopularLoading] = useState(-1);
  const [therapistSchedules, setTherapistSchedules] = useState([]);

  //get Programs//
  const getTherapy = async () => {
    try {
      setIsLoading({ action: "Get", loading: true });
      await BaseClient.get(
        APIEndpoints.getTherapy,
        {
          onSuccess: (res) => {
            if (!Array.isArray(res.data)) return;
            setPrograms(res.data);
          },
        },
        {
          onFailed: (err) => {
            return console.log(err);
          },
        }
      );
    } finally {
      setIsLoading({ action: "", loading: false });
    }
  };

  //post Program
  const addTherapy = async (payload, { onSuccess, onFailed }) => {
    try {
      setIsLoading({ action: " Add", loading: true });
      await BaseClient.post(APIEndpoints.addTherapy, payload, {
        headers: { "Content-Type": "multipart/form-data" },
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setIsLoading({ action: "", loading: false });
    }
  };

  // Edit Program
  const editTherapy = async (data, id, { onSuccess, onFailed }) => {
    try {
      const payload = data;
      setIsLoading({ action: "Edit", loading: true });

      await BaseClient.put(APIEndpoints.editTherapy + `/${id}`, payload, {
        headers: { "Content-Type": "multipart/form-data" },
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      getTherapy();
      setIsLoading({ action: "", loading: false });
    }
  };

  //Delete Program
  const deleteTherapy = async (id, { onSuccess, onFailed }) => {
    try {
      const payload = { therapyId: id };
      setIsLoading({ action: "Delete", loading: true });
      await BaseClient.post(APIEndpoints.deleteTherapy, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      getTherapy();
      setIsLoading({ action: "", loading: false });
    }
  };

  //Schedule Program
  const scheduleProgram = async ({ payload, onSuccess, onFailed }) => {
    try {
      setIsLoading(true);
      await BaseClient.post(APIEndpoints.scheduleTherphy, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setIsLoading(false);
    }
  };

  //Get Scheduled Sessions
  const getScheduledTheraphies = async () => {
    try {
      setIsLoading(true);

      await BaseClient.get(APIEndpoints.listScheduleTherapies, {
        onSuccess: (res) => {
          if (Array.isArray(res.data) && res.data.length > 0) {
            setScheduledTheraphies(res.data);
          }
        },
        onFailed: (error) => {
          console.log("error", error);
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Get Scheduled Therapy By Therapist
  const getScheduledTherapyByTherapist = async () => {
    try {
      setIsLoading(true);

      await BaseClient.get(APIEndpoints.getScheduledTherapyByTherapist, {
        onSuccess: (res) => {
          if (res.result != null) {
            console.log(res.result);
            setSortedScheduledTherapy(res.result);
          }
        },
        onFailed: (error) => {
          console.log("error", error);
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  //Schedule Therapy By Therapist
  const addScheduleTherapist = async (payload, { onSuccess, onFailed }) => {
    try {
      setIsLoading({ action: " Add", loading: true });
      await BaseClient.post(APIEndpoints.addTherapy, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setIsLoading({ action: "", loading: false });
    }
  };

  //Disable Therapy
  const disableTherapySingle = async (id, { onSuccess, onFailed }) => {
    try {
      setIsLoading({ action: "disable", loading: true });
      const payload = {};
      await BaseClient.put(APIEndpoints.disableTherapy + `/${id}`, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setIsLoading({ action: "", loading: false });
    }
  };

  //Enable Therapy
  const enableTherapySingle = async (id, { onSuccess, onFailed }) => {
    try {
      setIsLoading({ action: "enable", loading: true });
      const payload = {};
      await BaseClient.put(APIEndpoints.enableTherapy + `/${id}`, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setIsLoading({ action: "", loading: false });
    }
  };

  const popularizeTherapy = async (payload, { onSuccess, onFailed }) => {
    await BaseClient.patch(APIEndpoints.makePopularize, payload, {
      onSuccess: onSuccess,
      onFailed: onFailed,
    });
  };

  const getClientsScheduleData = async () => {
    try {
      setIsLoading({ action: "Get", loading: true });
      await BaseClient.get(
        APIEndpoints.getClientTherapyList,
        {
          onSuccess: (res) => {
            if (!res.result) return;
            setTherapistSchedules(res.result);
          },
        },
        {
          onFailed: (err) => {
            return console.log(err);
          },
        }
      );
    } finally {
      setIsLoading({ action: "", loading: false });
    }
  };

  return {
    getTherapy,
    programs,
    isLoading,
    addTherapy,
    editTherapy,
    deleteTherapy,
    scheduleProgram,
    getScheduledTheraphies,
    scheduledTheraphies,
    getScheduledTherapyByTherapist,
    sortedScheduledTherapy,
    addScheduleTherapist,
    enableTherapySingle,
    disableTherapySingle,
    popularizeTherapy,
    getClientsScheduleData,
    therapistSchedules,
  };
};

export default usePrograms;
