import React, { useState } from "react";
import { useContext } from "react";
import { APIEndpoints } from "../constants/APIEndpoints";
import { AppContext } from "../context/AppContext";
import BaseClient from "../Helper/BaseClient";

export default function useStream() {
  const [streamLoading, setStreamLoading] = useState(false);
  const [allStreams, setAllStreams] = useState([]);

  const changeBroadcastStatus = async ({ therapistId, bookingId, status }) => {
    await BaseClient.put(
      APIEndpoints.changeScheduleStreamStatus,
      { bookingId, status, therapistId },
      {
        onSuccess: (data) => {
          console.log("Broadcast Status Changed to " + status);
          console.log("<-- Broadcast Status Details -->" + data);
        },
        onFailed: (error) => {
          console.error("Broadcast Status Change Error " + status);
          console.error("<-- Broadcast Status Error Details -->" + error);
        },
      }
    );
  };

  const getAllScheduledStreams = async () => {
    try {
      setStreamLoading(true);
      await BaseClient.get(APIEndpoints.getScheduledTherapyByTherapist, {
        onSuccess: (res) => {
          console.log("res2", res);
          // debugger;
          if (res.result != null) {
            setAllStreams(res.result);
          }
        },
        onFailed: (err) => {
          console.log("error", err);
        },
      });
    } finally {
      setStreamLoading(false);
    }
  };

  return { changeBroadcastStatus, getAllScheduledStreams, allStreams };
}