import React, { useState, useEffect, useContext } from "react";
import Header from "../../Components/Header/Header";
import { AppContext } from "../../context/AppContext";
import * as Go from "react-icons/go";
import { OverlayTrigger, Tooltip, Overlay } from "react-bootstrap";
import ReactPaginate from 'react-paginate';
import "./Users.css"

function Users() {
  const { registerdUsers } = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const manageItems = registerdUsers.slice(indexOfFirstItem, indexOfLastItem);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  return (
    <>
      <Header />
      <main>
        <section class="main">
          <h2>Clients</h2>
          <div class="card user_card">
            <table class="table table-bordered">
              <thead style={{ textAlign: "center" }}>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Registered Date</th>
                  {/* <th>verification</th> */}
                </tr>
              </thead>
              <tbody>
                {manageItems.length > 0 &&
                  manageItems.map((users, index) => {
                    return (
                      <tr>
                        <td style={{ textAlign: "center" }} key={index}>{index + 1}</td>
                        <td>{users.name ?? "N/A"}</td>
                        <td>{users.email ?? "N/A"}</td>
                        <td style={{ textAlign: "center" }}>{users?.phonenumber ?? "N/A"}</td>
                        <td style={{ textAlign: "center" }}>{users?.registration_date ?? "N/A"}</td>
                        {/* <td>
                          {users.registeration_status == "verified" ? (
                            <i className="status green">
                              <Go.GoVerified />
                            </i>
                          ) : (
                            <i className="status red">
                              <Go.GoUnverified />
                            </i>
                          )}
                        </td> */}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="pagnte">
          <ReactPaginate
      previousLabel={"<"}
      nextLabel={">"}
      pageCount={Math.ceil(registerdUsers.length / itemsPerPage)}
      onPageChange={handlePageChange}
      containerClassName={"pagination"}
      previousLinkClassName={"page-link"}
      nextLinkClassName={"page-link"}
      disabledClassName={"disabled"}
      activeClassName={"paging__link--active"}
    />
          </div>
          
        </section>
      </main>
    </>
  );
}

export default Users;
