import React, { useState, useEffect, useContext } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import useAuth from "../../hook/useAuth";
import * as Hi from "react-icons/hi";
import * as Ri from "react-icons/ri";
import * as Fa from "react-icons/fa";
import * as Md from "react-icons/md";
import * as Ai from "react-icons/ai";
import * as IoMd from "react-icons/io"
import "./Header.css";
import Button from "@mui/material/Button";
import { Spinner } from "react-bootstrap";
import { MoonLoader } from "react-spinners";
import jwt_decode from "jwt-decode";
import useCookies from "../../hook/useCookies";
import { AppContext } from "../../context/AppContext";
import { confirmAlert } from "react-confirm-alert"; 
import "react-confirm-alert/src/react-confirm-alert.css";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const { logoutUser, loginUser } = useAuth();
  const [isUrl, setIsUrl] = useState(null);
  const [sidebar, setSidebar] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isRole, setIsRole] = useState(null);

  const { getValueFromCookies } = useCookies({ key: "UserPersistent" });
  const { stopAllStream } = useContext(AppContext);

  useEffect(() => {
    setIsUrl(location.pathname);
  }, [location]);

  useEffect(() => {
    const { accessToken } = getValueFromCookies();
    const { role } = jwt_decode(accessToken);
    setIsRole(role);
  }, []);

  const handleLogout = () => {
    setIsLoading(false);
    logoutUser();
    setTimeout(() => {
      navigate("/login");
    }, 1000);
  };
  const submit = () => {
    confirmAlert({
      title:'Are you sure?',
      buttons: [
        {
          className: "confirm-btn",
          label: "Yes",
          onClick: () => handleLogout(),
        },
        {
          className: "clear-button",
          label: "No",
        },
      ],
    });
  };

  return (
    <>
      <div className={`side-bar ${!sidebar ? "" : "hide"}`}>
        {/* <i onClick={() => setSidebar(false)}>
          <Ri.RiCloseCircleFill />
        </i> */}
        <div className="nav-logo">
          <img src={require("../../assets/logo_main.png")} id="logoImg1" />
        </div>
        <br />
        {isRole === "superadmin" && (
          <ul>
            <li className={"links " + (isUrl === "/" ? "active" : "")}>
              <Link to="/" className="nav-links">
                <i>
                  <Hi.HiOutlinePresentationChartBar />
                </i>
                Dashboard
              </Link>
              <p className={"tooltip_ " + (isUrl === "/" ? "active" : "")}>
                Dashboard
              </p>
            </li>

            <li className={"links " + (isUrl === "/therapist" ? "active" : "")}>
              <Link to="/therapist" className="nav-links">
                <i>
                  <Fa.FaChalkboardTeacher />
                </i>
                Therapists
              </Link>
              <span
                className={
                  "tooltip_ " + (isUrl === "/therapist" ? "active" : "")
                }
              >
                Therapists
              </span>
            </li>
            <li className={"links " + (isUrl === "/client" ? "active" : "")}>
              <Link to="/client" className="nav-links">
                <i>
                  <Fa.FaUsers />
                </i>
               Client Area
              </Link>
              <span
                className={"tooltip_ " + (isUrl === "/client" ? "active" : "")}
              >
             Client
              </span>
            </li>
            <li className={"links " + (isUrl === "/therapy" ? "active" : "")}>
              <Link to="/therapy" className="nav-links">
                <i>
                  <Md.MdCategory />
                </i>
                Manage Therapies
              </Link>
              <span
                className={"tooltip_ " + (isUrl === "/therapy" ? "active" : "")}
              >
                Therapies
              </span>
            </li>
            <li className={"links " + (isUrl === "/category" ? "active" : "")}>
              <Link to="/category" className="nav-links">
                <i className="cato_ico">
                  <IoMd.IoMdListBox />
                </i>
                Categories
              </Link>
              <span class={"tooltip_ " + (isUrl === "/category" ? "active" : "")}>Categories</span>
            </li>
            {/* <li className={"links " + (isUrl === "/subcategories" ? "active" : "")}>
            <Link to="/subcategories" className="nav-links">
              <i>
             <Ai.AiFillHdd />
              </i>
            SubCategories
            </Link>
          </li> */}
            {/* <li className={"links " + (isUrl === "/lives" ? "active" : "")}>
              <Link to="/lives" className="nav-links">
                <i>
                  <Ri.RiLiveFill />
                </i>
                View Live Sessions
              </Link>
              <span
                className={"tooltip_ " + (isUrl === "/lives" ? "active" : "")}
              >
                Lives
              </span>
            </li> */}
            <li
              className={
                "links " +
                (isUrl === "/schedule" || isUrl === "/schedule-approve"
                  ? "active"
                  : "")
              }
            >
              <Link to="/schedule" className="nav-links">
                <i>
                  <Fa.FaClipboardList />
                </i>
                Schedule Management
              </Link>
              <span
                className={
                  "tooltip_ " + (isUrl === "/schedule" ? "active" : "")
                }
              >
                Schedule
              </span>
            </li>
            <li className={"links " + (isUrl === "/purchases" ? "active" : "")}>
              <Link to="/purchases" className="nav-links">
                <i>
                  <Fa.FaShoppingBag />
                </i>
                Purchases
              </Link>
              <span
                className={
                  "tooltip_ " + (isUrl === "/purchases" ? "active" : "")
                }
              >
                Purchases
              </span>
            </li>
            <li className={"links " + (isUrl === "/settings" ? "active" : "")}>
              <Link to="/settings" className="nav-links">
                <i>
                  <Ri.RiSettings4Fill />
                </i>
                Settings
              </Link>
              <span
                className={
                  "tooltip_ " + (isUrl === "/settings" ? "active" : "")
                }
              >
                Settings
              </span>
            </li>
          </ul>
        )}
        {isRole === "instructor" && (
          <ul>
            <li className={"links " + (isUrl === "/" ? "active" : "")}>
              <Link to="/" className="nav-links">
                <i>
                  <Hi.HiOutlinePresentationChartBar />
                </i>
                Dashboard
              </Link>
              <span className={"tooltip_ " + (isUrl === "/" ? "active" : "")}>
                Dashboard
              </span>
            </li>
            <li
              className={
                "links " + (isUrl === "/session-manager" ? "active" : "")
              }
            >
              <Link to="/session-manager" className="nav-links">
                <i>
                  <Ri.RiVideoChatFill />
                </i>
                Session Manager
              </Link>
              <span
                className={"tooltip_ " + (isUrl === "/session-manager" ? "active" : "")}
              >
                Sessions
              </span>
            </li>
            <li
              className={
                "links " +
                (isUrl === "/schedule" || isUrl === "/add-schedule-therapist"
                  ? "active"
                  : "")
              }
            >
              <Link to="/schedule" className="nav-links">
                <i>
                  <Ai.AiFillSchedule />
                </i>
                Schedule Management
              </Link>
              <span
                className={
                  "tooltip_ " +
                  (isUrl === "/schedule" || isUrl === "/add-schedule-therapist"
                    ? "active"
                    : "")
                }
              >
                Schedule
              </span>
            </li>
          </ul>
        )}
        {/* <Lottie animationData={YogaWorkshopMailer} loop={true}/> */}
      </div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="mob_logo"></div>
        <div className="user_">
          {isLoading ? (
            <Button color="error" onClick={submit}>
              <Ai.AiOutlinePoweroff />
            </Button>
          ) : (
            // <Spinner animation="border" variant="danger" />
            <MoonLoader size={25} color="#dc3545" />
          )}
        </div>
      </nav>
    </>
  );
}

export default Header;
