import React, { useEffect, useState, useContext } from "react";
import "./ScheduleData.css";
import * as Bs from "react-icons/bs";
import * as Ai from "react-icons/ai";
import { AppContext } from "../../context/AppContext";
import Utils from "../../Utils/Utils";
import { Modal } from "antd";
import toast, { Toaster } from "react-hot-toast";

function DateSchedulePendingList() {
  const {
    pendingDateScheduleList,
    programs,
    approveSingleDateSchedule,
    getPendingDateSchedule,
  } = useContext(AppContext);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [price, setPrice] = useState("");
  const [error, setError] = useState(false);
  const [approveId, setApproveId] = useState(null);
   const [preview, setPreview] = useState(null);

  const findTherapyDataById = (id) => {
    return programs.find((x) => x._id == id);
  };

  const approveTherapy = async () => {
    if (price.length == 0) {
      setError(true);
      return;
    }
    const payload = {
      price,
      approved: true,
    };

    await approveSingleDateSchedule(approveId, payload, {
      onSuccess: async (res) => {
        toast.success("Schedule is Approved SuccessFully");
        await getPendingDateSchedule();
        setTimeout(() => {
          setPrice("");
          setShowScheduleModal(false);
        }, 2000);
      },
      onFailed: (error) => {
        toast.error("Approve Failed");
        console.log(error);
      },
    });
  };

  return (
    <>
      <Modal
        open={showScheduleModal}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => {
          setShowScheduleModal(false);
          setPrice("");
          setError(false);
        }}
        centered
      >
        <h3>Add Price to Schedule</h3>
        <p>{/* Therapy Name: <span>{therapyName}</span> */}</p>
        <p>
          Price:{" "}
          <input
            type="text"
            className="price form-control"
            value={price}
            placeholder={"Enter price"}
            onChange={(e) => setPrice(e.target.value)}
          />
          {error ? (
            <span style={{ color: "red" }}>Price connot be blank</span>
          ) : (
            ""
          )}
        </p>
        <button class="approve_btn" onClick={approveTherapy}>
          Approve
        </button>
        {/* <p>Therapist: }</p> */}
      </Modal>
      <div>
        {pendingDateScheduleList &&
          pendingDateScheduleList != null &&
          pendingDateScheduleList.map((list, key) => {
            const scheduleData = list.schedules;
            const therapist = list.therapistDetails;
            console.log("scheduleData", scheduleData);
            return (
              <>
                <div class="row">
                  {scheduleData &&
                    scheduleData.length > 0 &&
                    scheduleData.map((schedule) => {
                      console.log("schedule", schedule);
                      return (
                        <div class="col-lg-4 col-md-4 col-sm-4">
                          <div class="card date-card" id="date-pending-list">
                            <h1>
                              {schedule.therapyId != null
                                ? findTherapyDataById(schedule.therapyId)
                                    .therapyName
                                : "N/A"}
                            </h1>
                            <div class="d-flex">
                              <p>
                                <i>
                                  <Ai.AiOutlineCalendar />
                                </i>{" "}
                                <span>{Utils.formatDate(list.date)}</span>
                              </p>
                              <p className="mx-3">
                                <i>
                                  <Ai.AiOutlineClockCircle />
                                </i>{" "}
                                <span>{`${schedule?.startTime}-${schedule?.endTime}`}</span>
                              </p>
                            </div>
                            <p className="date-cont">
                              <i>
                                <Ai.AiOutlineUser />
                              </i>{" "}
                              {therapist?.name ?? "N/A"}
                            </p>
                            <p className="date-cont pb-2">
                              <i>
                                <Ai.AiOutlineMail />
                              </i>{" "}
                              {therapist?.email ?? "N/A"}
                            </p>

                            <p className="date-cont" id="date-desc">
                              {schedule?.description ?? "N/A"}
                            </p>
                            {!schedule.price && (
                              <button
                                class="approve"
                                id="date-approve"
                                title="Approve"
                                onClick={() => {
                                  setShowScheduleModal(true);
                                  setApproveId(schedule._id);
                                }}
                              >
                                <Bs.BsCheckAll />
                              </button>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </>
            );
          })}
      </div>
    </>
  );
}

export default DateSchedulePendingList;
