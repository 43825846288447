import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import Header from "../../Components/Header/Header";
import * as Bs from "react-icons/bs";
import * as Ai from "react-icons/ai";
import { DatePicker, TimePicker, Space } from "antd";
import { Form } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import moment from "moment";
import dayjs from "dayjs";
import toast, { Toaster } from "react-hot-toast";
import DateSchedule from "./DateSchedule";
import Utils from "../../Utils/Utils";

function TherapistAddScheduleTherapy() {
  const navigate = useNavigate();
  const { scheduleLoading, weeklySchedule, getWeeklyScheduleList } =
    useContext(AppContext);
  const [error, setError] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");

  // Intial Value for Schedule
  const initialValues = {
    startTime: null,
    endTime: null,
    startTimeValue: null,
    endTimeValue: null,
    therapyId: null,
    description: null,
  };

  const [scheduleSlots, setScheduleSlots] = useState([initialValues]);
  const [daySlots, setDaySlots] = useState([]);

  const { programs } = useContext(AppContext);

  // useEffect(() => {
  //   console.log("details", scheduleSlots);
  // }, [scheduleSlots]);

  // New schedule Add
  const addNewSchedule = () => {
    setScheduleSlots([...scheduleSlots, initialValues]);
    // console.log("slots", scheduleSlots);
  };

  // Delete a Schedule
  const deleteSchedule = (deleteIndex) => {
    const slots = [];
    scheduleSlots.forEach((slot, index) => {
      if (index != deleteIndex) {
        slots.push(slot);
      }
    });

    setScheduleSlots(slots);
  };

  // const handleValueChange = (e) =>{
  //   const {name, newValue} = e;
  //   setScheduleSlot({...scheduleSlot, [name]:newValue})
  // }

  const handleDayClick = (value) => {
    // daySlots.push(value)
    if (checkIfInclude(value)) {
      const result = daySlots.filter((day) => day != value);
      setDaySlots(result);
      return;
    }
    setDaySlots([...daySlots, value]);
  };

  const onDateSelect = (date, dateString) => {
    setSelectedDate(date);
    // console.log(selectedDate)
  };

  const checkIfInclude = (val) => {
    return daySlots.includes(val);
  };

  const scheduleTherapySubmit = async (e) => {
    e.preventDefault();

    const payload = {
      days: daySlots,
      scheduleDetails: [...scheduleSlots],
    };

    await weeklySchedule(payload, {
      onSuccess: async () => {
        toast.success("Schedule Added Successfully!", {
          style: {
            backgroundColor: "#fff",
            padding: "16px",
            color: "#252525",
          },
        });
        await getWeeklyScheduleList();
        setTimeout(() => {
          navigate("/schedule");
          setScheduleSlots(initialValues);
        }, 1000);
      },
      onFailed: (e) => console.log("Submission Failed", e),
    });
  };

  const onChangeDescription = (index, value) => {
    //?? Here, index  is the field number
    scheduleSlots[index].description = value;
    setScheduleSlots(scheduleSlots);
  };

  const onchangePrice = (index, value) => {
    scheduleSlots[index].price = value;
    setScheduleSlots(scheduleSlots);
  };

  const onChangeTimePicker = (time, timeString, index, type) => {
    switch (type) {
      case "startTime":
        console.log("timeString", timeString);
        scheduleSlots[index].startTime = timeString;
        scheduleSlots[index].startTimeValue = time;
        setScheduleSlots([...scheduleSlots]);
        return;
      case "endTime":
        scheduleSlots[index].endTime = timeString;
        scheduleSlots[index].endTimeValue = time;
        setScheduleSlots([...scheduleSlots]);
        return;
      default:
        return;
    }
  };

  return (
    <>
      <Toaster position="top-center" />
      <Header />
      <main>
        <section class="main">
          <button className="back_btn" onClick={() => navigate("/schedule")}>
            <Ai.AiOutlineLeft />
          </button>
          <h1>Add New Schedule</h1>
          <div class="card duration-card schedule_card">
            <h6 className="headsch">Schedule period</h6>
            <div class="d-flex">
              <Form className="d-flex">
                <label for="">Date</label>
                <Form.Check
                  className="schedule_switch"
                  type="switch"
                  id="custom-switch"
                  onClick={() => {
                    setToggle(!toggle);
                  }}
                  checked={!toggle}
                />
                <label for="">Weekly</label>
              </Form>
            </div>
            {!toggle ? (
              <div className="week-days">
                <div class="form-group mt-2">
                  <h6 className="mt-1">Select week Days for schedule</h6>
                  <div className="week-days mt-1">
                    <div
                      className={
                        checkIfInclude("Sunday") ? "day select" : "day"
                      }
                      name="sun"
                      onClick={() => handleDayClick("Sunday")}
                    >
                      S
                    </div>
                    <div
                      className={
                        checkIfInclude("Monday") ? "day select" : "day"
                      }
                      name="mon"
                      onClick={() => handleDayClick("Monday")}
                    >
                      M
                    </div>
                    <div
                      className={
                        checkIfInclude("Tuesday") ? "day select" : "day"
                      }
                      name="tue"
                      onClick={() => handleDayClick("Tuesday")}
                    >
                      T
                    </div>
                    <div
                      className={
                        checkIfInclude("Wednesday") ? "day select" : "day"
                      }
                      name="wed"
                      onClick={() => handleDayClick("Wednesday")}
                    >
                      W
                    </div>
                    <div
                      className={
                        checkIfInclude("Thursday") ? "day select" : "day"
                      }
                      name="thu"
                      onClick={() => handleDayClick("Thursday")}
                    >
                      T
                    </div>
                    <div
                      className={
                        checkIfInclude("Friday") ? "day select" : "day"
                      }
                      name="fri"
                      onClick={() => handleDayClick("Friday")}
                    >
                      F
                    </div>
                    <div
                      className={
                        checkIfInclude("Saturday") ? "day select" : "day"
                      }
                      name="sat"
                      onClick={() => handleDayClick("Saturday")}
                    >
                      S
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <h6 className="text-center mt-2">Date</h6>
                <Space
                  direction="vertical"
                  style={{ width: "100%", borderColor: "transparent" }}
                >
                  <DatePicker
                    style={{
                      width: "100%",
                      borderColor: "transparent",
                      outline: "none",
                      boxShadow: "0 0 0 transparent",
                    }}
                    format={"DD-MM-YYYY"}
                    className="form-control"
                    value={initialValues.date}
                    onChange={onDateSelect}
                  />
                </Space>
              </>
            )}
          </div>

          {!toggle ? (
            <form onSubmit={(e) => scheduleTherapySubmit(e)}>
              {scheduleSlots.map((schedule, index) => {
                return (
                  <div class="card inst_card schedule_card" key={index}>
                    <h5> #{index + 1}</h5>
                    <div class="row">
                      <div class="col-lg-4 col-sm-4 col-md-4">
                        <div class="form-group">
                          <label for="">Therapy</label>
                          <select
                            name="therapyId"
                            id=""
                            class="form-select"
                            value={initialValues.therapyId}
                            onChange={(e) => {
                              setScheduleSlots((preValue) =>
                                [...preValue].map((x) =>
                                  x.therapyId === initialValues.therapyId
                                    ? { ...x, therapyId: e.target.value }
                                    : x
                                )
                              );
                            }}
                          >
                            <option value="0" selected>
                              Select Therapy
                            </option>
                            {programs.length > 0 &&
                              programs.map((therapy, index) => {
                                // console.log(therapy);
                                return (
                                  <>
                                    <option value={therapy._id}>
                                      {therapy.therapyName}
                                    </option>
                                  </>
                                );
                              })}
                          </select>
                          {error ? (
                            <span id="err">Please Select a therapy</span>
                          ) : (
                            ""
                          )}
                        </div>

                        {/* <div class="form-group mt-2">
                        <label for="">Price</label>
                        <input
                          type="text"
                          name=""
                          id=""
                          class="form-control details"
                          placeholder="Enter price details"
                          onChange={(e) => {
                            const value = e.target.value;
                            onchangePrice(index, value);
                          }}
                          value={initialValues.price}
                          required
                        />
                        {error && initialValues.price?.length === 0 ? (
                          <>
                            <span style={{ color: "red" }}>
                              Please add therapyname
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div> */}
                      </div>
                      <div class="col-lg-4 col-sm-4 col-md-4">
                        <div class="form-group ">
                          <label for="">Start Time</label>
                          <Space
                            direction="vertical"
                            style={{
                              width: "100%",
                              borderColor: "transparent",
                            }}
                          >
                            <TimePicker
                              use12Hours
                              format="h:mm A"
                              onChange={(time, timeString) =>
                                onChangeTimePicker(
                                  time,
                                  timeString,
                                  index,
                                  "startTime"
                                )
                              }
                              defaultOpenValue={dayjs("00:00", "HH:mm")}
                              value={schedule.startTimeValue}
                              style={{
                                width: "100%",
                                borderColor: "transparent",
                                outline: "none",
                                boxShadow: "0 0 0 transparent",
                              }}
                              className="form-control"
                              placeholder="Select Start Time"
                            />
                          </Space>
                        </div>
                        <div class="form-group mt-2">
                          <label for="">End Time</label>
                          <Space
                            direction="vertical"
                            style={{
                              width: "100%",
                              borderColor: "transparent",
                            }}
                          >
                            <TimePicker
                              use12Hours
                              format="h:mm A"
                              onChange={(time, timeString) =>
                                onChangeTimePicker(
                                  time,
                                  timeString,
                                  index,
                                  "endTime"
                                )
                              }
                              defaultOpenValue={dayjs("00:00", "HH:mm")}
                              value={schedule.endTimeValue}
                              style={{
                                width: "100%",
                                borderColor: "transparent",
                                outline: "none",
                                boxShadow: "0 0 0 transparent",
                              }}
                              className="form-control"
                            />
                          </Space>
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-4 col-md-4">
                        <label for="">Description</label>
                        <textarea
                          className="form-control"
                          name=""
                          id=""
                          rows="4"
                          onChange={(e) => {
                            const value = e.target.value;
                            onChangeDescription(index, value);
                          }}
                          value={schedule.description}
                        ></textarea>
                      </div>
                    </div>
                    {index !== 0 && scheduleSlots.length > 1 && (
                      <button
                        class="delete_schedule"
                        onClick={() => deleteSchedule(index)}
                      >
                        <Bs.BsFillTrashFill />
                      </button>
                    )}
                  </div>
                );
              })}

              <button class="add_schedule" onClick={() => addNewSchedule()}>
                +
              </button>

              <button type="submit" className="form_btn mb-4">
                {scheduleLoading.action == "Add" &&
                  scheduleLoading.loading == true}
                Add
              </button>
            </form>
          ) : (
            <DateSchedule selectedDate={selectedDate} />
          )}
        </section>
      </main>
    </>
  );
}

export default TherapistAddScheduleTherapy;
