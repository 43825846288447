import React from "react";
import { Row, Col } from "react-bootstrap";
import AnimatedRedirect from "../../assets/lottie/Redirect.json";
import Lottie from "lottie-react";
import "./RedirectError.css";
import PageNotFoundAnimation from "../../assets/lottie/pagenotfound.json";
import CustomErrors from "../../constants/Errors";
import { useNavigate } from "react-router";

function PageNotFound() {
  const navigate = useNavigate();

  return (
    <div>
      <div className="container red-con">
        <Row className="red-row">
          <Col>
            <Lottie
              className="grn"
              animationData={PageNotFoundAnimation}
              loop={true}
            ></Lottie>
          </Col>
          <Col>
            <div className="mx-auto text-center">
              <h6 className="red-msg">Page Not Found</h6>

              <p className="msg-para">{CustomErrors.PAGE_NOT_FOUND}</p>

              <button
                role="button"
                className="redirect mx-auto"
                onClick={() => navigate("/")}
              >
                <span class="text">Go to Home</span>
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default PageNotFound;
