/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState, useEffect, useContext } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Button } from "antd";
import Avatar from "react-avatar-edit";
import toast, { Toaster } from "react-hot-toast";
import { Dialog } from "primereact/dialog";
import * as Ai from "react-icons/ai";
import "antd/dist/reset.css";
import Profile from "../../assets/placeholder.png";
import { AppContext } from "../../context/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../Components/Header/Header";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./Home.css";
import Utils from "../../Utils/Utils";
function EditTherapist() {
  const navigate = useNavigate();
  const params = useParams();
  const [dialogues, setDialogues] = useState(false);
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [editInstructorDetails, setEditInstructorDetails] = useState({});
  const [formErrors, setFormErrors] = useState("");

  const {
    getTherapist,
    loggedInstructorDetails,
    editInstructor,
    getCurrentLoggedTherapistDetails,
  } = useContext(AppContext);

  // useEffect(() => {
  //   if (loggedInstructorDetails.photo != null) {
  //     setFile(loggedInstructorDetails.photo);
  //   }
  // }, [loggedInstructorDetails]);

  const onCrop = (view) => {
    setFile(Utils.createBlobFile(view));
    debugger;
  };
  const onClose = (view) => {};
  const closeCropModal = () => {
    setDialogues(false);
  };

  useEffect(() => {
    if (loggedInstructorDetails != null) {
      setEditInstructorDetails(loggedInstructorDetails);
    }
  }, [loggedInstructorDetails]);

  useEffect(() => {
    validator(editInstructorDetails);
  }, [editInstructorDetails]);

  const onChangeEditingField = ({ name, value }) => {
    if (name.length == 0) return;
    let EditInstDetails = editInstructorDetails;
    EditInstDetails[name] = value;
    setEditInstructorDetails({ ...EditInstDetails });
  };

  const validator = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "This field cannot be blank";
    }
    if (!values.email) {
      errors.email = "This field cannot be blank";
    }
    if (!values.phonenumber) {
      errors.phonenumber = "This field cannot be blank";
    }
    if (!values.profession) {
      errors.profession = "This field cannot be blank";
    }
    if (!values.desigination) {
      errors.desigination = "This field cannot be blank";
    }
    if (!values.experience) {
      errors.experience = "This field cannot be blank";
    }
    if (!values.bio && values.bio !== null) {
      errors.bio = "This field cannot be blank";
    }
    return errors;
  };

  const updateInstructorDetails = async (e) => {
    e.preventDefault();
    const errors = validator(editInstructorDetails);
    setFormErrors(errors);
    if (Object.keys(errors).length == 0) {
      setFormErrors({});
      delete editInstructorDetails._id;

      const bodyFormData = Utils.createFormDataFromFormValues({
        ...editInstructorDetails,
        file: file,
      });
      console.log(bodyFormData.getAll("bio"), "editInstructorDetails");
      // debugger;
      const id = params.id;
    

      editInstructor(bodyFormData, id, {
        onSuccess: async (data) => {
          await getCurrentLoggedTherapistDetails();
          toast.success("User details updated successfully!");
          setTimeout(() => {
            navigate("/");
          }, 1000);
        },

        onFailed: (err) => {
          console.log("error", err);
          toast.error("Error updating user details!");
        },
      });
    }
  };
  const navigateBack = () => {
    window.location.href = "/";
  };
  return (
    <div>
      <Toaster position="top-center" />
      <Header />

      <Container fluid="md">
        <main>
          <section className="main">
          <button className="back_btn" onClick={() => navigateBack()}>
              <Ai.AiOutlineLeft />
            </button>
            <h2>Edit Therapist</h2>
            <form
              className="form-control pro-add"
              onSubmit={(e) => updateInstructorDetails(e)}
            >
              <Row>
                <Col>
                  <div className="user-profile">
                    <div className="flex flex-column justify-content-center align-items-center">
                      <img
                        src={
                          file != null
                            ? URL.createObjectURL(file)
                            : editInstructorDetails.photo != null
                            ? process.env.REACT_APP_IMAGE_URL +
                              editInstructorDetails.photo
                            : Profile
                        }
                        alt=""
                        style={{ maxWidth: "100%" }}
                        onClick={() => {
                          setDialogues(true);
                        }}
                      />
                     
                        <div className="confirmation-content flex flex-column align-items-center">
                          <div className="flex flex-column align-items-center mt-5 w-12">
                            <div className="flex flex-column justify-content-around w-12 mt-4">
                            
                              <Button
                                label="Save"
                                onClick={closeCropModal}
                                className="mt-4 saved"
                              >
                                Save
                              </Button>
                            </div>
                          </div>
                        </div>
                  
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="col-pro">
                <Col>
                  <div class="form-group">
                    <label for="name" className="text-nowrap">
                      Full Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      onChange={(e) => onChangeEditingField(e.target)}
                      value={editInstructorDetails.name}
                    />
                    {formErrors.name && !editInstructorDetails.name && (
                      <p>{formErrors.name}</p>
                    )}
                  </div>
                </Col>
                <Col>
                  <div class="form-group">
                    <label for="email">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={editInstructorDetails.email}
                      onChange={(e) => onChangeEditingField(e.target)}
                    />
                    {formErrors.email && !editInstructorDetails.email && (
                      <p>{formErrors.email}</p>
                    )}
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <label for="phone">Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      name="phonenumber"
                      value={editInstructorDetails.phonenumber}
                      onChange={(e) => onChangeEditingField(e.target)}
                    />
                    {formErrors.phonenumber &&
                      !editInstructorDetails.phonenumber && (
                        <p>{formErrors.phonenumber}</p>
                      )}
                  </div>
                </Col>
              </Row>
              <br />

              <Row className="col-pro">
                <Col>
                  <label>Profession</label>
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="profession"
                    value={editInstructorDetails.profession}
                    onChange={(e) => onChangeEditingField(e.target)}
                  />
                  {formErrors.profession &&
                    !editInstructorDetails.profession && (
                      <p>{formErrors.profession}</p>
                    )}
                </Col>
                <Col>
                  <label for="">Experience</label>
                  <input
                    type="text"
                    name="experience"
                    className="form-control"
                    value={editInstructorDetails.experience}
                    onChange={(e) => onChangeEditingField(e.target)}
                  />
                  {formErrors.experience &&
                    !editInstructorDetails.experience && (
                      <p>{formErrors.experience}</p>
                    )}
                </Col>
              </Row>
              <Row className="col-pro">
                <Col>
                  <label for="">Designation</label>
                  <input
                    type="text"
                    name="desigination"
                    className="form-control"
                    value={editInstructorDetails.desigination}
                    onChange={(e) => onChangeEditingField(e.target)}
                  />
                  {formErrors.desigination &&
                    !editInstructorDetails.desigination && (
                      <p>{formErrors.desigination}</p>
                    )}
                </Col>
                <Col>
                  <label for="">Bio</label>
                  <input
                    type="text"
                    name="bio"
                    className="form-control"
                    value={editInstructorDetails.bio}
                    onChange={(e) => onChangeEditingField(e.target)}
                  />
                  {formErrors.bio && !editInstructorDetails.bio && (
                    <p>{formErrors.bio}</p>
                  )}
                </Col>
              </Row>
              {/* SocialMedia Icon */}
              {/* <Row>
                <Col className="text-center">
                  <i className="socialmedia">
                    <a href="" className="icon_socialmedia">
                      <Ai.AiFillFacebook />
                    </a>
                    <a href="" className="icon_socialmedia">
                      <Ai.AiFillTwitterSquare />
                    </a>
                    <a href="" className="icon_socialmedia">
                      <Ai.AiFillInstagram />
                    </a>
                    <a href="" className="icon_socialmedia">
                      <Ai.AiFillLinkedin />
                    </a>
                  </i>
                </Col>
              </Row> */}
              <Row className="buttons col-pro">
                <Col className="text-center">
                  <Col className="mx-auto">
                    <button type="submit" className="edit_btn">
                      Update
                    </button>
                  </Col>
                </Col>
              </Row>
            </form>
          </section>
        </main>
      </Container>
    </div>
  );
}

export default EditTherapist;
