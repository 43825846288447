/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Container, Table } from "react-bootstrap";
import { Button } from "antd";
import Avatar from "react-avatar-edit";
import * as Ai from "react-icons/ai";
import * as Bs from "react-icons/bs";
import * as Ti from "react-icons/ti";
import * as Md from "react-icons/md";
import Profile from "../../assets/placeholder.png";
import Header from "../../Components/Header/Header";
import { AppContext } from "../../context/AppContext";
import { confirmAlert } from "react-confirm-alert";
import { Dialog } from "primereact/dialog";
import "./Instructors.css";
import ReactPaginate from "react-paginate";
import "antd/dist/reset.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import toast, { Toaster } from "react-hot-toast";

function Instructors() {
  const navigate = useNavigate();
  const {
    therapists,
    getTherapist,
    enableTherapistSingle,
    disableTherapistSingle,
  } = useContext(AppContext);
  const initialValues = {
    phonenumber: "",
    firstname: "",
    lastname: "",
    bio: "",
    experience: "",
    email: "",
    desigination: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [showModal, setShowModal] = useState(false);
  const [fileCrop, setFileCrop] = useState(false);
  const [dialogues, setDialogues] = useState(false);
  const [saveFile, setSaveFile] = useState([]);
  const [singleTherapist, setSingleTherapist] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [file, setFile] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentShow = therapists.slice(indexOfFirstItem, indexOfLastItem);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const handleValidate = (e) => {
    const { value } = e.target;
    setFormValues({ ...formValues, name: value });
  };
  const onClose = (view) => {
    setFileCrop(view);
  };

  const onCrop = () => {
    setFileCrop(null);
  };

  const saveDoc = () => {
    setSaveFile([...saveFile, { fileCrop }]);
    setDialogues(false);
  };
  const uploadFile = saveFile.map((item) => item.fileCrop);

  const navigateToAddInstructor = () => {
    navigate("/add-therapist");
    setSingleTherapist(therapists);
  };

  const handleEnableDisable = (id) => {
    const singleTherapist = therapists.filter((x) => x._id == id);

    // console.log(singleTherapist[0].status ,singleTherapist[0].status == "disabled")
    if (singleTherapist[0].status == "disabled") {
      enableTherapistSingle(id, {
        onSuccess: async () => {
          toast.success("Therpist Enabled");
          await getTherapist();
        },
        onFailed: (err) => {
          console.log(err);
        },
      });
    } else if ((singleTherapist[0].status = "enabled")) {
      disableTherapistSingle(id, {
        onSuccess: async () => {
          toast.success("Therpist Disabled");
          await getTherapist();
        },
        onFailed: (err) => {
          console.log(err);
        },
      });
    }
  };

  const enableDiableTherapist = (id) => {
    confirmAlert({
      title: "Are you sure?",
      message: "This action will cause the therapist's action",
      buttons: [
        {
          className: "confirm-btn",
          label: "Yes",
          onClick: () => handleEnableDisable(id),
        },
        {
          className: "clear-button",
          label: "No",
        },
      ],
    });
  };

  return (
    <>
      <Toaster position="top-center" />
      <div className={!showModal ? "modal_bg" : "modal_bg show"}>
        <div className="add_modal">
          <i className="close" onClick={() => setShowModal(!showModal)}>
            <Ai.AiFillCloseCircle />
          </i>
          <div className="instruct"></div>
          <h2 className="ml-3 mt-3">Add Therapist</h2>
          <Row>
            <Col>
              <div className="profile">
                <div className="flex flex-column justify-content-center align-items-center">
                  <img
                    src={uploadFile.length > 0 ? uploadFile : Profile}
                    alt=""
                    name="image"
                    style={{ maxWidth: "100%" }}
                    onClick={(e) => {
                      setDialogues(true);
                      setFile(e.target.files[0]);
                    }}
                  />
                  <Dialog
                    visible={dialogues}
                    header={() => (
                      <>
                        <p>Update Profile Picture</p>
                      </>
                    )}
                    onHide={() => setDialogues(false)}
                  >
                    <div className="confirmation-content flex flex-column align-items-center">
                      <div className="flex flex-column align-items-center mt-5 w-12">
                        <div className="flex flex-column justify-content-around w-12 mt-4">
                          <Avatar
                            width={400}
                            height={300}
                            onClose={onClose}
                            onCrop={onCrop}
                          />
                          <Button
                            label="Save"
                            onClick={saveDoc}
                            className="mt-4"
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Dialog>
                </div>
              </div>
            </Col>
          </Row>
          <form className="form-control add_form">
            <Row>
              <Col>
                <div className="form-group">
                  <label for="firstname">First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="firstname"
                    value={formValues.firstname}
                    onChange={handleValidate}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label for="lastname">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="lastname"
                    value={formValues.lastname}
                    onChange={handleValidate}
                  />
                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xl={6}>
                <div className="form-group">
                  <label for="email">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    value={formValues.email}
                    onChange={handleValidate}
                  />
                </div>
              </Col>
              <Col xl={6}>
                <div className="form-group">
                  <label for="phone">Phone</label>
                  <input
                    type="text"
                    className="form-control"
                    name="phonenumber"
                    value={formValues.phonenumber}
                    onChange={handleValidate}
                  />
                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xl={4}>
                <div className="form-group">
                  <label for="exp">Experience</label>
                  <div className="d-flex">
                    <input
                      type="text"
                      className="form-control"
                      style={{ width: "50%" }}
                      name="experience"
                      value={formValues.experience}
                      onChange={handleValidate}
                    />
                    <select
                      className="form-select ml-2"
                      style={{ width: "50%" }}
                    >
                      <option selected value="Personal Trainer">
                        Years
                      </option>
                      <option value="Mental therapist">Months</option>
                    </select>
                  </div>
                </div>
              </Col>
              <Col xl={4}>
                <div className="form-group">
                  <label for="exp">Experience in</label>
                  <select className="form-select">
                    <option selected hidden>
                      Select a category
                    </option>
                    <option value="Personal Trainer">Personal Trainer</option>
                    <option value="Mental therapist">Mental therapist</option>
                    <option value="Yoga Teacher">Yoga Teacher</option>
                    <option>Other</option>
                  </select>
                </div>
              </Col>
              <Col xl={4}>
                <label for="exp">Other</label>
                <input type="text" className="form-control" disabled />
              </Col>
            </Row>
            <br />
            {/* social media section of instructors */}
            <Row>
              <label className="mb-3">Social Media Links</label>
              <Col xl={4}>
                <div className="form-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Facebook"
                  />
                </div>
              </Col>
              <Col xl={4}>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Instagram"
                  />
                </div>
              </Col>
              <Col xl={4}>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Twitter"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <label for="Bio">Bio</label>
                <textarea
                  className="bio"
                  name="bio"
                  value={formValues.bio}
                  onChange={handleValidate}
                ></textarea>
              </Col>
            </Row>

            <br />

            <button type="submit" className="form_btn">
              Add
            </button>
          </form>
        </div>
      </div>
      <header>
        <Header />
      </header>
      <main>
        <section className="main">
          <button className="add_btn" onClick={() => navigateToAddInstructor()}>
            + Add
          </button>
          <h1>Therapist</h1>
          <Container className="table-co">
            <div className="card inst_card mt-3 table-responsive" id="cat_card">
              <Table>
                <table role="table" className="table table-bordered user_card">
                  <thead style={{ textAlign: "center" }}>
                    <tr role="row">
                      <th scope="col">#</th>
                      <th scope="col">Full Name</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Email</th>
                      <th scope="col">Designation</th>
                      <th scope="col">Experience</th>
                      <th scope="col">Bio</th>
                      <th scope="col">Status</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(currentShow) &&
                      currentShow.length > 0 &&
                      currentShow.map((therapist, index) => {
                        const image =
                          process.env.REACT_APP_BASE_URL + therapist.photo;
                        return (
                          <>
                            <tr role="row">
                              <td role="cell" className="pro" key={index}>
                                {index + 1}
                              </td>
                              <td role="cell" className="pro">
                                {therapist?.name ?? "N/A"}
                              </td>
                              <td role="cell" className="pro">
                                {therapist?.phonenumber ?? "N/A"}
                              </td>
                              <td role="cell" className="pro">
                                {therapist?.email ?? "N/A"}
                              </td>
                              <td role="cell" className="pro">
                                {therapist?.desigination ?? "N/A"}
                              </td>
                              <td role="cell" className="pro">
                                {therapist?.experience ?? "N/A"}
                              </td>
                              <td role="cell" className="pro">
                                {therapist?.bio ?? "N/A"}
                              </td>
                              <td role="cell" className="pro">
                                {therapist?.active ? (
                                  <i class="status green">
                                    <Ti.TiTick />
                                  </i>
                                ) : (
                                  <i class="status red">
                                    <Md.MdOutlineClose />
                                  </i>
                                )}
                              </td>
                              <td className="pro">
                                <button
                                  className="btsuc"
                                  onClick={() => {
                                    navigate(
                                      `/edit-therapist/${therapist._id}`
                                    );
                                  }}
                                  singleTherapist={singleTherapist}
                                >
                                  <i className="edit">
                                    {" "}
                                    <Bs.BsFillPencilFill />
                                  </i>
                                </button>
                                <button
                                  className={
                                    therapist.status == "disabled"
                                      ? "therapist_enaDis red"
                                      : "therapist_enaDis"
                                  }
                                  onClick={() =>
                                    enableDiableTherapist(therapist._id)
                                  }
                                  singleTherapist={singleTherapist}
                                >
                                  <i className="edit">
                                    {therapist.status == "disabled" ? (
                                      <Ai.AiFillEyeInvisible />
                                    ) : (
                                      <Ai.AiFillEye />
                                    )}
                                  </i>
                                </button>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </Table>
            </div>
            <div className="pagnte">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={Math.ceil(therapists.length / itemsPerPage)}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
                disabledClassName={"disabled"}
                activeClassName={"paging__link--active"}
              />
            </div>
          </Container>
        </section>
      </main>
    </>
  );
}

export default Instructors;
