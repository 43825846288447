import React, { useEffect } from "react";
import { useState } from "react";
import "./LiveSession.css";
import * as Ai from "react-icons/ai";
import * as Md from "react-icons/md";
import * as Bs from "react-icons/bs";
import * as IoMd from "react-icons/io";
import * as Fa from "react-icons/fa";
import * as Im from "react-icons/im";
import { Modal } from "antd";
import { Row } from "react-bootstrap";
import useAgora from "../../hook/useAgora";
import Webcam from "react-webcam";

import { useParams } from "react-router";
import MediaPlayer from "../../Pages/streamManager/Mediaplayer";
import ChatSession from "./ChatSession";
import CircleLoader from "../common/CircleLoader";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import useStream from "../../hook/useStream";
import StreamStatus from "../../constants/StreamStatus";
import StreamErrors from "../../Pages/StreamErrors/StreamErrors";
import toast, { Toaster } from "react-hot-toast";

function LiveSession() {
  const [currentSession, setCurrentSession] = useState(null);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  const [selectedVideoDevice, setSelectedVideoDevice] = useState(null);
  const [selectedAudioDevice, setSelectedAudioDevice] = useState(null);

  const {
    remoteUsers,
    mainPlayerSrc,
    setMainPlayerSrc,
    sidePlayerSrc,
    setSidePlayerSrc,
  } = useContext(AppContext);

  const params = useParams();
  const { loggedInstructorDetails, allStreams, programs } =
    useContext(AppContext);

  const mainPlayerRef = React.useRef();

  const { changeBroadcastStatus } = useStream();

  const {
    localTracks,
    leave,
    join,
    joinState,
    toggleScreenShare,
    localScreenTracks,
    sharingScreen,
    joining,
    agoraError,
    toggleCamera,
    toggleMic,
    muted,
    videoHide,
    videoDevices,
    audioDevices,
    setRemoteUsers,
    customAVTrackFromDevices,
  } = useAgora({
    channelId: params.id,
    onSharingScreenEnded: () => {
      const element = document.getElementById("screensharebtn");
      if (element != null && element.classList != null) {
        element.classList.remove("active-class");
      }
    },
    onUserLeave: (uid) => {
      handleUserLeave();
    },
    onJoin: async () => {
      await changeBroadcastStatus({
        bookingId: params.id,
        status: StreamStatus.STREAM_STARTED,
        therapistId: loggedInstructorDetails._id,
      });
    },
    onLeave: async () => {
      await changeBroadcastStatus({
        bookingId: params.id,
        status: StreamStatus.STREAM_STOPPED,
        therapistId: loggedInstructorDetails._id,
      });
      window.location.href = "/session-manager";
    },
    onSelectVideoDevice: (device) => {
      setSelectedVideoDevice(device);
    },
    onSelectAudioDevice: (device) => {
      setSelectedAudioDevice(device);
    },
  });

  const handleUserLeave = (uid) => {
    const element = document.getElementById(`${uid}_player`);
    console.log("Element", element);
  };

  useEffect(() => {
    initFunction();
  }, [allStreams]);

  useEffect(() => {
    console.log("currentSession", currentSession);
  }, [currentSession]);

  const initFunction = () => {
    console.log("allStreams", allStreams);
    if (
      allStreams != null &&
      Array.isArray(allStreams.schedules) &&
      allStreams.schedules.length != 0
    ) {
      const session = allStreams.schedules.find(
        (bookings) => bookings._id == params.id
      );

      if (session == null) return;

      const therapyDetails = programs.find(
        (program) => program._id == session.scheduleDetails.therapyId
      );
      setCurrentSession({ ...session, therapyDetails });
    }
  };

  const handleMicrophoneClick = async () => {
    const element = document.getElementById("microphoneBtn");
    if (element != null && element.classList != null) {
      if (muted == false) {
        await toggleMic();
        element.classList.toggle("active-class");
      } else {
        await toggleMic();
        element.classList.remove("active-class");
      }
    }
  };

  const handleVideoClick = async () => {
    const element = document.getElementById("videobtn");
    if (element != null && element.classList != null) {
      if (videoHide == false) {
        await toggleCamera();
        element.classList.toggle("active-class");
      } else {
        await toggleCamera();
        element.classList.remove("active-class");
      }
    }
  };

  const handleScreenShareClick = async () => {
    const element = document.getElementById("screensharebtn");
    if (element != null && element.classList != null) {
      if (sharingScreen == false) {
        await toggleScreenShare();
        element.classList.toggle("active-class");
      } else {
        await toggleScreenShare();
        element.classList.remove("active-class");
      }
    }
  };

  const handleLeaveButtonClick = async () => {
    await leave();
  };

  useEffect(() => {
    getMainPlayerTrack();
  }, [remoteUsers]);

  useEffect(() => {
    console.log("localTracks", localTracks);
  }, [localTracks]);

  const getMainPlayerTrack = () => {
    setMainPlayerSrc(null);
    if (remoteUsers.length == 0) return;
    let pinnedRemoteUserId = null;
    pinnedRemoteUserId = window.sessionStorage.getItem("pinnedUserId");
    if (pinnedRemoteUserId == null) {
      pinnedRemoteUserId = remoteUsers[0].uid;
    }
    let foundedUser = remoteUsers.find(
      (user) => user.uid == pinnedRemoteUserId
    );
    if (!foundedUser) {
      // user has left the session
      // update pinnedRemoteUserId to null or to the first user in remoteUsers array
      pinnedRemoteUserId =
        remoteUsers[0].uid != null ? remoteUsers[0].uid : null;
      if (!pinnedRemoteUserId) return;
      window.sessionStorage.setItem("pinnedUserId", pinnedRemoteUserId);
      foundedUser = remoteUsers.find((user) => user.uid == pinnedRemoteUserId);
    }

    if (!foundedUser) return;
    window.sessionStorage.setItem("pinnedUserId", foundedUser.uid);
    setMainPlayerSrc(foundedUser);
  };

  const onClickPinBtn = ({ uid }) => {
    const foundedUser = remoteUsers.find((user) => user.uid == uid);
    if (foundedUser == null) return;
    setMainPlayerSrc(foundedUser);
    window.sessionStorage.setItem("pinnedUserId", foundedUser.uid);
  };

  const handleVideoOutput = (videoId) => {
    const videoSource = videoDevices.find((x) => x.deviceId == videoId);
    setSelectedVideoDevice(videoSource);
    console.log(selectedVideoDevice);
  };

  const handleAudioOutput = (audioId) => {
    const audioSource = audioDevices.find((x) => x.deviceId == audioId);
    setSelectedAudioDevice(audioSource);
    console.log(selectedAudioDevice);
  };

  const handleAllSettings = async () => {
    await customAVTrackFromDevices(
      selectedVideoDevice,
      selectedAudioDevice,
      () => {
        setShowSettingsModal(false);
      }
    );
  };

  return (
    <>
      <Toaster position="top-center" />
      {joining == false && joinState == true && agoraError == null && (
        <div className="container-fluid mb-3" style={{ background: "#fff" }}>
          <div className="row">
            <div className="col-md-8">
              {currentSession != null && (
                <Modal
                  distryOnClose={true}
                  open={showInfoModal}
                  onCancel={() => setShowInfoModal(false)}
                  onOk={() => setShowInfoModal(false)}
                  cancelButtonProps={{ style: { display: "none" } }}
                  okButtonProps={{
                    style: {
                      backgroundColor: "#249782",
                      transition: "all 0.2s ease-in-out",
                      width: "50%",
                      margin: "0 auto",
                      display: "block",
                    },
                  }}
                >
                  <p className="modal_head">Therapy Details</p>
                  <h4
                    className="modal_names"
                    title="Yoga begginers course -  Special class for all members"
                  >
                    {currentSession.therapyDetails.therapyName
                      ? currentSession.therapyDetails.therapyName
                      : "Not Assigned"}
                  </h4>
                  <p class="modal_sub">
                    {/* {
                      JSON.parse(currentSession.therapyDetails.category)
                        .category
                    } */}
                  </p>
                  <p>{currentSession.therapyDetails.therapyDescription}</p>
                  <hr />
                  {/* <div class="row">
                    <div class="col-6">
                      <p class="modal_head">Therapist Details</p>
                      <h4>{currentSession.therapistDetails.name}</h4>
                      <p class="modal_sub">
                        {currentSession.therapyDetails.desigination}
                      </p>
                      <p class="therapist_modal_details">
                        <Ai.AiOutlineMail />{" "}
                        {currentSession.therapistDetails.email}
                      </p>
                    </div>
                    <div class="col-6">
                      <div class="therapist_profile">
                        <img
                          src={
                            currentSession.therapistDetails.photo
                              ? currentSession.therapistDetails.photo
                              : imagePlaceholder
                          }
                        />
                      </div>
                    </div>
                  </div> */}
                </Modal>
              )}

              {/* Settings Modal */}
              <Modal
                open={showSettingsModal}
                onCancel={() => setShowSettingsModal(false)}
                onOk={() => setShowSettingsModal(false)}
                footer={null}
                centered={true}
                style={{ width: "600px" }}
              >
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="settings-screen">
                      {selectedVideoDevice != null ? (
                        <Webcam
                          height={120}
                          width="100%"
                          videoConstraints={selectedVideoDevice}
                        />
                      ) : (
                        <img
                          src={require("../../assets/nest_placeholder.jpg")}
                        />
                      )}
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <label for="">Camera</label>
                    <select
                      name=""
                      id=""
                      class="settings-select mt-2 mb-2"
                      onChange={(e) => handleVideoOutput(e.target.value)}
                      value={
                        selectedVideoDevice != null &&
                        selectedVideoDevice.deviceId != null
                          ? selectedVideoDevice.deviceId
                          : null
                      }
                    >
                      <option
                        className="setting-options"
                        value=""
                        selected
                        hidden
                      >
                        &#xf030; <span className="ps-2">Choose Camera</span>
                      </option>
                      {videoDevices != null &&
                        videoDevices.map((videoSource) => {
                          return (
                            <option
                              className="setting-options"
                              value={videoSource.deviceId}
                              key={videoSource.deviceId}
                            >
                              {videoSource.label}
                            </option>
                          );
                        })}
                    </select>
                    <label for="">Mic</label>
                    <select
                      name=""
                      id=""
                      class="settings-select mt-2"
                      onChange={(e) => handleAudioOutput(e.target.value)}
                      value={
                        selectedAudioDevice != null &&
                        selectedAudioDevice.deviceId != null
                          ? selectedAudioDevice.deviceId
                          : null
                      }
                    >
                      <option
                        className="setting-options"
                        value=""
                        selected
                        hidden
                      >
                        &#xf130; <span className="ps-2">Choose Microphone</span>
                      </option>
                      {audioDevices != null &&
                        audioDevices.map((audioSource, index) => {
                          return (
                            <option
                              className="setting-options"
                              value={audioSource.deviceId}
                              key={index}
                            >
                              {audioSource.label}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div class="button-grp d-flex mt-2">
                  <button class="set_btn ok" onClick={handleAllSettings}>
                    OK
                  </button>
                  <button
                    class="set_btn cancel ms-2"
                    onClick={() => setShowSettingsModal(false)}
                  >
                    cancel
                  </button>
                </div>
              </Modal>
              <div className="live-streaming">
                <div class="container-fluid m-3">
                  <Row>
                    <div className="d-flex ps-0">
                      <button
                        className="button-back"
                        onClick={() => {
                          window.location.href = "/session-manager";
                        }}
                      >
                        <Md.MdArrowBackIosNew />
                      </button>

                      <div className="mt-2 ms-2 d-flex">
                        <h5>
                          {currentSession?.therapyDetails != null
                            ? currentSession?.therapyDetails?.therapyName
                            : "N/A"}
                        </h5>
                        <div
                          class="classCard"
                          onClick={() => setShowInfoModal(true)}
                        >
                          <Bs.BsInfoCircleFill id="class_info" />
                          <p>Therapy</p>
                        </div>
                      </div>
                    </div>
                    <div className="class_screen">
                      {remoteUsers.length == 0 && localTracks != null ? (
                        <MediaPlayer
                          videoTrack={localTracks.localVideoTrack}
                          audioTrack={localTracks.localAudioTrack}
                          style={{ aspectRatio: "16/9" }}
                          ref={mainPlayerRef}
                        />
                      ) : (
                        mainPlayerSrc != null && (
                          <MediaPlayer
                            videoTrack={mainPlayerSrc.videoTrack}
                            audioTrack={mainPlayerSrc.audioTrack}
                            style={{ aspectRatio: "16/9" }}
                            ref={mainPlayerRef}
                            mainPlayer={true}
                            remoteUserId={window.sessionStorage.getItem(
                              "pinnedUserId"
                            )}
                          />
                        )
                      )}
                    </div>
                  </Row>
                  <div class="row">
                    <div class="col-md-3">
                      <div class="stream_logo mt-4">
                        <img
                          src={require("../../assets/logo-web.png")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="controllers">
                        <div class="cntrl_btn">
                          <button
                            id="microphoneBtn"
                            className="control-btn"
                            onClick={handleMicrophoneClick}
                          >
                            <Bs.BsFillMicMuteFill className="controller_btn_ico" />
                          </button>
                          <label for="Mic" class="controller-label">
                            Mic
                          </label>
                        </div>
                        <div class="cntrl_btn">
                          <button
                            id="videobtn"
                            className="control-btn"
                            onClick={handleVideoClick}
                          >
                            <Fa.FaVideoSlash className="controller_btn_ico" />
                          </button>
                          <label for="Video" class="controller-label">
                            Video
                          </label>
                        </div>
                        <div class="cntrl_btn">
                          <button
                            className="control-btn hungup"
                            onClick={handleLeaveButtonClick}
                          >
                            <Im.ImPhoneHangUp className="controller_btn_ico" />
                          </button>
                          <label for="Video" class="controller-label mt-2">
                            Leave
                          </label>
                        </div>
                        <div class="cntrl_btn">
                          <button
                            id="screensharebtn"
                            className="control-btn"
                            onClick={() => {
                              toast.success("This feature is not available 🙂");
                              //handleScreenShareClick
                            }}
                          >
                            <Md.MdOutlineScreenShare className="controller_btn_ico" />
                          </button>
                          <label for="Screen Share" class="controller-label">
                            Share
                          </label>
                        </div>
                        <div class="cntrl_btn">
                          <button
                            className="control-btn"
                            onClick={() => setShowSettingsModal(true)}
                          >
                            <IoMd.IoMdSettings className="controller_btn_ico" />
                          </button>
                          <label for="Settings" class="controller-label">
                            Settings
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 col-sm-12 p-1"
              style={{ background: "#fff" }}
            >
              <div className="mx-auto">
                <ChatSession
                  onClickPin={onClickPinBtn}
                  chatRoomId={params.id}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {joining == true && agoraError == null && (
        <>
          <CircleLoader type="lottieLoader" height="400px" width="400px" />
        </>
      )}
      {agoraError != null && (
        <>
          <StreamErrors title="Failed To Join Session" content={agoraError} />
        </>
      )}
    </>
  );
}

export default LiveSession;
