export default class CustomErrors {
  static USER_CREDENTIALS_ERROR =
    "We apologize, but we are unable to process your request to join due to invalid credentials or system error";
  static USER_NOT_BOOKED_ERROR =
    "We're sorry, but you are unable to join the session as it has not been booked or scheduled. Please schedule a session before attempting to join. Thank you.";
  static USER_CONNECTION_LOST_ERROR =
    "We apologize for the inconvenience, but the connection to the server has been lost. Please try reconnecting or contact technical support for further assistance.";
  static USER_BROADCAST_ERROR =
    "We apologize for the interruption during your video session. An unknown error occurred, and we suggest trying to reconnect. If the issue persists, please contact technical support for further assistance.";
  static USER_TOKEN_AUTHORIZATION_ERROR =
    "We apologize, but an error occurred with the user token authorization from Token Service. Please verify your credentials or contact technical support for further assistance.";
  static USER_MEDIA_PERMISSION_ERROR =
    "We're sorry, but it seems that your media permissions are not properly configured on our website. Please grant the necessary permissions or adjust your browser settings and try again. If the issue persists, please contact technical support for further assistance.";
  static USER_NETWORK_TIMEOUT_ERROR =
    "We apologize for the inconvenience, but it seems that a network timeout error has occurred. Please check your internet connection and try again. If the issue persists, please contact technical support for further assistance. Thank you.";
  static UNEXPECTED_ERROR =
    "We apologize for the inconvenience, but it seems that something unexpected has occurred, and our system is unable to process your request at this time. Please try again later or contact technical support for further assistance. Thank you for your understanding.";
  static DEVICE_NOT_FOUND =
    "We're sorry, but we were unable to detect a microphone or camera on your device. This may be due to a hardware issue or a problem with your device's settings. Please check that your microphone and camera are properly connected and enabled, and try again.";
  static PAGE_NOT_FOUND =
    "Sorry, the page you're looking for cannot be found. It may have been moved or deleted, or the URL you entered is incorrect. Please check the URL and try again or use the search bar to find what you need. Contact our support team for help if you continue to experience issues. Apologies for the inconvenience.";
  static UNAUTHORIZED_ACCESS =
    "It seems that you do not have permission to access or redirect to that page. Please check with the website or system administrator to ensure that you have the necessary permissions to access the desired page or resource";
}
